import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { REDUCER_KEY_CONSULTANTS, ACTION_NAME_FETCH_CONSULTANT_ITEM_AVAILABILITY } from '../constants'
import ApiClient from '../../api/ApiClient';

export interface AvailabilitiesState {
  isFetching: boolean;
  availabilities: any[];
}

const initialState: AvailabilitiesState = {
  isFetching: false,
  availabilities: [],
}

const fetchConsultantItemAvailability = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANT_ITEM_AVAILABILITY}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchConsultantItemAvailability(id);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_CONSULTANTS,
  initialState,
  reducers: {
    resetAvailabilities: (state) => {
      state.isFetching = false;
      state.availabilities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultantItemAvailability.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchConsultantItemAvailability.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchConsultantItemAvailability.fulfilled, (state, { payload }) => {
        state.availabilities = payload.data;
        state.isFetching = false;
      });
  },
});

const {
  resetAvailabilities,
} = actions;

export {
  resetAvailabilities,
  fetchConsultantItemAvailability,
};

export default reducer;
