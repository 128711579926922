import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import {
  REDUCER_KEY_REVIEWS,
  ACTION_NAME_FETCH_REVIEWS_LIST,
  ACTION_NAME_FETCH_REVIEW_ITEM,
  ACTION_NAME_APPROVE_REVIEW_ITEM, ACTION_NAME_DECLINE_REVIEW_ITEM,
} from '../constants'
import ApiClient from '../../api/ApiClient';

export interface ReviewsState {
  isFetching: boolean;
  isFetched: boolean;
  reviews: any[];
  count: number;
  page: number;
  selectedReviewItem: any | undefined;
}

const initialState: ReviewsState = {
  isFetching: false,
  isFetched: false,
  reviews: [],
  count: 0,
  page: 0,
  selectedReviewItem: undefined,
}

const fetchReviewsList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  property?: string,
  type?: "desc" | "asc",
}>(
  `${REDUCER_KEY_REVIEWS}/${ACTION_NAME_FETCH_REVIEWS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    property,
    type= "asc",
  }) => {
    const response = await ApiClient.fetchReviews({ offset, limit, property, type });
    return response;
  },
);

const fetchReviewItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_REVIEWS}/${ACTION_NAME_FETCH_REVIEW_ITEM}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchReviewItem(id);
    return response;
  },
);

const approveReview = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_REVIEWS}/${ACTION_NAME_APPROVE_REVIEW_ITEM}`,
  async ({
    id,
  }, {dispatch}) => {
    const response = await ApiClient.approveReviewItem(id);
    await dispatch(fetchReviewItem({id}));
    return response;
  },
);

const declineReview = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_REVIEWS}/${ACTION_NAME_DECLINE_REVIEW_ITEM}`,
  async ({
    id,
  }, {dispatch}) => {
    const response = await ApiClient.declineReviewItem(id);
    await dispatch(fetchReviewItem({id}));
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_REVIEWS,
  initialState,
  reducers: {
    resetReviews: (state) => {
      state.isFetching = false;
      state.isFetched = false;
      state.reviews = [];
      state.count = 0;
      state.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewsList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchReviewsList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchReviewsList.fulfilled, (state, {payload}) => {
        state.reviews = payload.data.orderFeadbacks;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(fetchReviewItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchReviewItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchReviewItem.fulfilled, (state, {payload}) => {
        state.selectedReviewItem = payload.data;
        state.isFetching = false;
      })
      .addCase(approveReview.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(approveReview.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(approveReview.fulfilled, (state, {payload}) => {
        state.isFetching = false;
      })
      .addCase(declineReview.pending, (state) => {
        state.isFetching = true;
        state.isFetched = false;
      })
      .addCase(declineReview.rejected, (state) => {
        state.isFetching = false;
        state.isFetched = false;
      })
      .addCase(declineReview.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        state.isFetched = true;
      });
  },
});

const {
  resetReviews,
} = actions;

export {
  resetReviews,
  fetchReviewsList,
  fetchReviewItem,
  approveReview,
  declineReview,
};

export default reducer;
