import React from 'react';
import {
  Alert as MuiAlert,
  Snackbar,
} from '@mui/material';
import { spacing } from '@mui/system';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getSnackNotficationsSelector } from '../redux/selectors/getSnackNotficationsData';
import { setSnackNotification } from '../redux/slices/snackNotifications';

import styled from 'styled-components/macro';

const Alert = styled(MuiAlert)(spacing);

const SnackComponent = () => {
  const dispatch = useAppDispatch();
  const snackNotificationsState = useAppSelector(getSnackNotficationsSelector);

  const onClose = () => {
    dispatch(setSnackNotification({ isOpen: false, message: undefined }));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: snackNotificationsState.vertical,
        horizontal: snackNotificationsState.horizontal,
      }}
      open={snackNotificationsState.isOpen}
      onClose={onClose}
      key={snackNotificationsState.vertical + snackNotificationsState.horizontal}
    >
      <Alert variant="filled" severity={snackNotificationsState.severity}>
        {snackNotificationsState.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackComponent;
