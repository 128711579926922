import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
  ACTION_NAME_FETCH_SERVICE_CATALOG_ITEM, ACTION_NAME_FETCH_SERVICE_CATALOG_ITEMS_FROM_LEAD,
  ACTION_NAME_FETCH_SERVICE_CATALOG_LIST, ACTION_NAME_REMOVE_SERVICE_CATALOG_ITEM,
  REDUCER_KEY_SERVICE_CATALOG,
} from '../constants';
import ApiClient from '../../api/ApiClient';
import {ServiceCatalogEntriesProps, ServiceCatalogEntryCreateProps} from "../../types/serviceCatalogEntries";

export interface ServiceCatalogState {
  isFetching: boolean;
  serviceCatalogEntries: ServiceCatalogEntriesProps[];
  count: number;
  page: number;
  selectedEntry: ServiceCatalogEntriesProps | undefined;
  isSaving: boolean;
}

const initialState: ServiceCatalogState = {
  isFetching: false,
  serviceCatalogEntries: [],
  count: 0,
  page: 0,
  selectedEntry: undefined,
  isSaving: false,
};

const fetchServiceCatalogEntriesList = createAsyncThunk<
    ServiceCatalogEntriesProps[],
    { offset?: number; limit?: number; type?: 'desc' | 'asc' }
>(
    `${REDUCER_KEY_SERVICE_CATALOG}/${ACTION_NAME_FETCH_SERVICE_CATALOG_LIST}`,
    async ({ offset = 0, limit = 25, type = 'asc'}) => {
      const response = await ApiClient.fetchServiceCatalogEntries(offset, limit);

      return response.data.map((entry: {
        id: string;
        productId: number;
        product: { name: string };
        moduleId: number;
        productModule: { name: string };
        workTypeId: number;
        workType: { name: string };
        workUnit: string;
        task: string;
        vertical: string;
        complexity: string;
        estimate: number;
        leadSourceId: number;
      }) => ({
        id: entry.id,
        productId: entry.productId,
        productName: entry.product?.name,
        moduleId: entry.moduleId,
        moduleName: entry.productModule?.name,
        workTypeId: entry.workTypeId,
        workTypeName: entry.workType?.name,
        workUnit: entry.workUnit,
        task: entry.task,
        vertical: entry.vertical,
        complexity: entry.complexity,
        estimate: entry.estimate,
        leadSourceId: entry.leadSourceId,
      }));
    }
);


const fetchServiceCatalogEntry = createAsyncThunk<
    ServiceCatalogEntriesProps,
    { id: number }
>(
    `${REDUCER_KEY_SERVICE_CATALOG}/${ACTION_NAME_FETCH_SERVICE_CATALOG_ITEM}`,
    async ({ id }) => {
      const response = await ApiClient.fetchServiceCatalogEntry(id);

      const entry = response.data;
      return {
        id: entry.id,
        productId: entry.productId,
        productName: entry.product?.name,
        moduleId: entry.moduleId,
        moduleName: entry.productModule?.name,
        workTypeId: entry.workTypeId,
        workTypeName: entry.workType?.name,
        workUnit: entry.workUnit,
        task: entry.task,
        vertical: entry.vertical,
        complexity: entry.complexity,
        estimate: entry.estimate,
        leadSourceId: entry.leadSourceId,
      };
    }
);

const fetchServiceCatalogEntriesFromLead = createAsyncThunk<
    ServiceCatalogEntriesProps[],
    { id: string }
>(
    `${REDUCER_KEY_SERVICE_CATALOG}/${ACTION_NAME_FETCH_SERVICE_CATALOG_ITEMS_FROM_LEAD}`,
    async ({ id }) => {
      const response = await ApiClient.fetchServiceCatalogEntriesFromLeadId(id);

      return response.data.map((entry: {
        id: string;
        productId: number;
        product: { name: string };
        moduleId: number;
        productModule: { name: string };
        workTypeId: number;
        workType: { name: string };
        workUnit: string;
        task: string;
        vertical: string;
        complexity: string;
        estimate: number;
        leadSourceId: number;
      }) => ({
        id: entry.id,
        productId: entry.productId,
        productName: entry.product?.name,
        moduleId: entry.moduleId,
        moduleName: entry.productModule?.name,
        workTypeId: entry.workTypeId,
        workTypeName: entry.workType?.name,
        workUnit: entry.workUnit,
        task: entry.task,
        vertical: entry.vertical,
        complexity: entry.complexity,
        estimate: entry.estimate,
        leadSourceId: entry.leadSourceId,
      }));
    }
);

const createServiceCatalogEntryItem = createAsyncThunk<ServiceCatalogEntriesProps, { serviceCatalogItem: ServiceCatalogEntryCreateProps }>(
    `${REDUCER_KEY_SERVICE_CATALOG}/${ACTION_NAME_REMOVE_SERVICE_CATALOG_ITEM}`,
    async ({ serviceCatalogItem }, { rejectWithValue }) => {
      try {
        const { ...values } = serviceCatalogItem;
        const response = await ApiClient.createServiceCatalogEntry({ ...values });

        const entry = response.data;
        return {
          id: entry.id,
          productId: entry.productId,
          productName: entry.product?.name,
          moduleId: entry.moduleId,
          moduleName: entry.productModule?.name,
          workTypeId: entry.workTypeId,
          workTypeName: entry.workType?.name,
          workUnit: entry.workUnit,
          task: entry.task,
          vertical: entry.vertical,
          complexity: entry.complexity,
          estimate: entry.estimate,
          leadSourceId: entry.leadSourceId,
        };
      } catch (error) {
        // @ts-ignore
        return rejectWithValue(error.response.data);
      }
    }
);


const removeServiceCatalogEntryItem = createAsyncThunk<AxiosResponse<any>, { serviceCatalogItemId: number }>(
    `${REDUCER_KEY_SERVICE_CATALOG}/${ACTION_NAME_REMOVE_SERVICE_CATALOG_ITEM}`,
    async ({ serviceCatalogItemId }, { dispatch }) => {
      const response = await ApiClient.deleteServiceCatalogEntry(serviceCatalogItemId);
      return { ...response, data: { ...response.data, serviceCatalogItemId } };
    },
);


const { actions, reducer } = createSlice({
  name: REDUCER_KEY_SERVICE_CATALOG,
  initialState,
  reducers: {
    resetServiceCatalogEntries: (state) => {
      state.isFetching = false;
      state.serviceCatalogEntries = [];
      state.count = 0;
      state.page = 0;
      state.selectedEntry = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceCatalogEntriesList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchServiceCatalogEntriesList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchServiceCatalogEntriesList.fulfilled, (state, { payload }) => {
        state.serviceCatalogEntries = payload;
        state.count = payload.length;
        state.isFetching = false;
      })
      .addCase(fetchServiceCatalogEntriesFromLead.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchServiceCatalogEntriesFromLead.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchServiceCatalogEntriesFromLead.fulfilled, (state, { payload }) => {
        state.serviceCatalogEntries = payload;
        state.isFetching = false;
      })
      .addCase(fetchServiceCatalogEntry.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchServiceCatalogEntry.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchServiceCatalogEntry.fulfilled, (state, { payload }) => {
        state.selectedEntry = payload;
        state.isFetching = false;
      })
      .addCase(createServiceCatalogEntryItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(createServiceCatalogEntryItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(createServiceCatalogEntryItem.fulfilled, (state, { payload }) => {
        state.serviceCatalogEntries.push(payload);
        state.isSaving = false;
      });
  },
});

const { resetServiceCatalogEntries } = actions;

export { resetServiceCatalogEntries, fetchServiceCatalogEntriesList, fetchServiceCatalogEntriesFromLead, fetchServiceCatalogEntry, createServiceCatalogEntryItem, removeServiceCatalogEntryItem };

export default reducer;
