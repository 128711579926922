import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_CUSTOMERS,
  ACTION_NAME_FETCH_CUSTOMERS_LIST,
  ACTION_NAME_CHANGE_CUSTOMER_ACTIVE_STATUS,
  ACTION_NAME_CHANGE_CUSTOMER_EMAIL,
} from '../constants'
import ApiClient from '../../api/ApiClient';

export interface CustomersState {
  isFetching: boolean;
  customers: any[];
  count: number;
  page: number;
  selectedCustomerItem: any | undefined;
}

const initialState: CustomersState = {
  isFetching: false,
  customers: [],
  count: 0,
  page: 0,
  selectedCustomerItem: undefined,
}

const fetchCustomersList = createAsyncThunk<AxiosResponse<any>, { offset?: number, limit?: number, property?: string, type?: "desc" | "asc", search?: string, isActive?: boolean }>(
  `${REDUCER_KEY_CUSTOMERS}/${ACTION_NAME_FETCH_CUSTOMERS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    property,
    type= "asc",
    search,
    isActive,
  }) => {
    const response = await ApiClient.fetchCustomers({ offset, limit, property, type, search, isActive });
    return response;
  },
);

const changeCustomerActiveStatus = createAsyncThunk<AxiosResponse<any>, { userId: number }>(
  `${REDUCER_KEY_CUSTOMERS}/${ACTION_NAME_CHANGE_CUSTOMER_ACTIVE_STATUS}`,
  async ({ userId }) => {
    const response = await ApiClient.changeCustomersActiveStatus(userId);
    return response;
  },
);

const changeCustomerEmail = createAsyncThunk<AxiosResponse<any>, { userId: number, email: string }>(
  `${REDUCER_KEY_CUSTOMERS}/${ACTION_NAME_CHANGE_CUSTOMER_EMAIL}`,
  async ({ userId, email }) => {
    const response = await ApiClient.changeCustomerEmail({ id: userId, email });
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_CUSTOMERS,
  initialState,
  reducers: {
    resetCustomers: (state) => {
      state.isFetching = false;
      state.customers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCustomersList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchCustomersList.fulfilled, (state, { payload }) => {
        state.customers = payload.data.customers;
        state.count = payload.data.count;
        state.isFetching = false;
      })

      .addCase(changeCustomerActiveStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeCustomerActiveStatus.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(changeCustomerActiveStatus.fulfilled, (state, { payload }) => {
        state.customers = state.customers.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isFetching = false;
      })

      .addCase(changeCustomerEmail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeCustomerEmail.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(changeCustomerEmail.fulfilled, (state, { payload }) => {
        state.customers = state.customers.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isFetching = false;
      });
  },
});

const {
  resetCustomers,
} = actions;

export {
  resetCustomers,
  fetchCustomersList,
  changeCustomerActiveStatus,
  changeCustomerEmail,
};

export default reducer;
