import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { persistReducer } from 'redux-persist';
import persistStorage from 'redux-persist/lib/storage';
import {
  REDUCER_KEY_AUTH,
  ACTION_NAME_LOGOUT_USER,
  ACTION_NAME_LOGIN_USER,
  ACTION_NAME_REFRESH_USER_TOKEN,
  ACTION_NAME_IMPERSONATE, ACTION_NAME_AGENCY_IMPERSONATE,
  ACTION_NAME_SIGNUP_USER,
  ACTION_NAME_FORGOT_PASSWORD,
  ACTION_NAME_VERIFICATION_CODE,
  ACTION_NAME_CHECK_LINK,
} from '../constants'
import { RootState } from '../store';
import ApiClient from '../../api/ApiClient';

export interface AuthState {
  loading: boolean;
  user: any;
  isAuthenticated: boolean;
  isInitialized: boolean;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  impersonation?: {
    accessToken: string,
    refreshToken: string,
    userId: number,
  };
}

const initialState: AuthState = {
  loading: false,
  user: null,
  isAuthenticated: false,
  isInitialized: true,
  accessToken: undefined,
  refreshToken: undefined,
  impersonation: undefined,
}

interface ILoginResponse {
  id: number | string;
  email: string;
  firstName: string;
  lastName: string;
  role: {
    name: string,
  };
  accessToken: string;
  refreshToken: string;
}

interface LoginUserParams {
  email: string;
  password: string;
}

interface SignUpParams {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  code: string;
}

const loginUser = createAsyncThunk<AxiosResponse<ILoginResponse>,
  LoginUserParams,
  { state: RootState }>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_LOGIN_USER}`,
  async ({
    email,
    password,
    // eslint-disable-next-line arrow-body-style
  }, { dispatch }) => {
    const response = await ApiClient.logIn({ email, password });
    return response;
  },
);

const logoutUser = createAsyncThunk<AxiosResponse<ILoginResponse>>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_LOGOUT_USER}`,
  async () => {
    const response = await ApiClient.logOut();
    return response;
  },
);

const signUp = createAsyncThunk<AxiosResponse<any>, SignUpParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_SIGNUP_USER}`,
  async (signupParams) => {
    const response = await ApiClient.signUp(signupParams);
    return response;
  }
)

interface IRefreshUserParams {
  refreshToken: string;
}

const refreshAccessToken = createAsyncThunk<AxiosResponse<ILoginResponse>, IRefreshUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_REFRESH_USER_TOKEN}`,
  async ({
    refreshToken,
  }, { dispatch }) => {
    const response = await ApiClient.refreshUserToken(refreshToken);
    if (response.data.accessToken) {
      ApiClient.setToken(response.data.accessToken);
    }
    return response;
  },
);

interface IImpersonation {
  userId: number;
  accessToken: string;
  refreshToken: string;
}

interface IImpersonationParams {
  userId: number;
}

const impersonateAccessToken = createAsyncThunk<AxiosResponse<IImpersonation>, IImpersonationParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_IMPERSONATE}`,
  async ({
    userId,
  }, { dispatch }) => {
    const response = await ApiClient.fetchImpersonateAccessToken(userId);
    return response;
  },
);

interface IForgotPassword {
  email: string;
}

const forgotPassword = createAsyncThunk<AxiosResponse<any>, IForgotPassword>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_FORGOT_PASSWORD}`,
  async (forgotParams) => {
    const response = await ApiClient.forgotPassword(forgotParams);
    return response;
  },
)

interface IRecoverPassword {
  password: string;
  token: string;
}

const recoverPassword = createAsyncThunk<AxiosResponse<any>, IRecoverPassword>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_FORGOT_PASSWORD}`,
  async (forgotParams) => {
    const response = await ApiClient.recoverPassword(forgotParams);
    return response;
  },
)

export interface IVerificationCode {
  email: string;
  code: string;
}

const verificationCode = createAsyncThunk<AxiosResponse<any>, IVerificationCode>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_VERIFICATION_CODE}`,
  async (verificationParams) => {
    const response = await ApiClient.verificationCode(verificationParams);
    return response;
  }
)

const checkLink = createAsyncThunk<AxiosResponse<any>, string>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_CHECK_LINK}`,
  async (hash) => {
    const response = await ApiClient.checkLink(hash);
    return response;
  }
)

interface IAgencyImpersonation {
  id: number;
  accessToken: string;
  email: string;
  firstName: string;
  isActive: boolean;
  isVirtual: boolean;
  lastChangeActiveAt: string;
  lastName: string;
  refreshToken: string;
  role: { id: number, name: string };
}

interface IAgencyImpersonationParams {
  agencyId: number;
  userId: number;
}

const agencyImpersonateAccessToken = createAsyncThunk<AxiosResponse<IAgencyImpersonation>, IAgencyImpersonationParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_AGENCY_IMPERSONATE}`,
  async ({
    agencyId,
    userId,
  }, { dispatch }) => {
    const response = await ApiClient.fetchAgencyImpersonateAccessTokenByUserId(agencyId, userId);
    return response;
  },
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const {actions, reducer} = createSlice({
  name: REDUCER_KEY_AUTH,
  initialState,
  reducers: {
    clearUser: (state) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      state.isInitialized = true;
      state.accessToken = undefined;
      state.refreshToken = undefined;
    },
    setUser: (state, {payload}) => {
      state.user = payload;
      state.loading = false;
    },
    setToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken;
    },
    setRefreshToken: (state, action: PayloadAction<{ refreshToken: string }>) => {
      state.refreshToken = action.payload.refreshToken;
    },
    resetImpersonation: (state) => {
      state.impersonation = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.isInitialized = false;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isInitialized = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.isInitialized = true;
        state.user = { ...payload.data };
        state.accessToken = payload.data.accessToken;
        state.refreshToken = payload.data.refreshToken;
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.isInitialized = false;
      })
      .addCase(signUp.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isInitialized = true;
      })
      .addCase(signUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.isInitialized = true;
        state.user = { ...payload.data };
        state.accessToken = payload.data.accessToken;
        state.refreshToken = payload.data.refreshToken;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.isInitialized = false;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isInitialized = true;
        state.user = null;
      })
      .addCase(logoutUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isInitialized = true;
        state.user = null;
        state.accessToken = undefined;
        state.refreshToken = undefined;
      })
      .addCase(impersonateAccessToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(impersonateAccessToken.rejected, (state) => {
        state.loading = false;
      })
      .addCase(impersonateAccessToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.impersonation = payload.data;
      })

      .addCase(agencyImpersonateAccessToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(agencyImpersonateAccessToken.rejected, (state) => {
        state.loading = false;
      })
      .addCase(agencyImpersonateAccessToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.impersonation = {
          accessToken: payload.data.accessToken,
          refreshToken: payload.data.refreshToken,
          userId: payload.data.id,
        };
      })
  },
});

const persistConfig = {
  key: 'auth',
  storage: persistStorage,
  whitelist: ['user', 'isAuthenticated', 'isInitialized', 'accessToken', 'refreshToken'],
};

const persistedReducer = persistReducer(
  persistConfig,
  reducer,
);

const {
  clearUser,
  setUser,
  setToken,
  setRefreshToken,
  resetImpersonation,
} = actions;

export {
  clearUser,
  setUser,
  refreshAccessToken,
  loginUser,
  logoutUser,
  setToken,
  setRefreshToken,
  impersonateAccessToken,
  agencyImpersonateAccessToken,
  resetImpersonation,
  signUp,
  forgotPassword,
  recoverPassword,
  verificationCode,
  checkLink,
};
export default persistedReducer;
