import { AxiosRequestConfig } from 'axios';
import AxiosClient, { AxiosClientNotAuth } from './AxiosClient';
import { TagsCreationProps, TagProps } from '../types/tag';
import { CompanyCreationProps, CompanyEnterpriseEditingProps } from '../types/companies';
import { FeedbackProps } from '../types/feedback';
import {
  ProductEditingProps,
  UpdateWorkTypeProps,
  UpdateProductModulesProps,
  ProductCreatingProps,
  AddWorkTypeProps,
  AddProductModulesProps,
  ProductsFilterQueryProps,
} from '../types/products';
import {
  PurchaseOrderCreation,
  PurchaseOrderEdition,
  PurchaseOrderFilterQueryProps,
} from '../types/purchaseOrders';
import { BusinessRuleAction, BusinessRuleCreation, BusinessRuleEdition } from '../types/businessRules';
import { OrderingType } from '../types/common';
import {
  WeeklyBillingsFilterProps,
  InvoicesFilterProps,
  InvoiceCreationProps,
  InvoiceEditingProps,
} from '../types/enterprise';
import { AgencyCreationProps, AgencyEnterpriseEditingProps } from '../types/agencies';
import dataToImgFile from '../utils/dataToImgFile';
import { LeadManagerCreateProps, LeadManagerUpdateProps } from '../types/leadManagers';
import { LeadCreationProps } from '../types/leads';
import { CreateLeadSourceProps } from '../types/leadSources';
import { PayloadUpdateDto, PayoutCreateDto, PayoutsFilterProps } from '../types/payouts';
import { PreOrderCreateProps } from '../types/preOrder';
import { ServiceCatalogEntryCreateProps } from '../types/serviceCatalogEntries';
import { OrderCreateDto, OrderEditingProps, OrderFilterQueryProps } from '../types/order';
import { ConsultantFilterQueryProps } from '../types/consultant';
import { ExportType } from '../constants';
import { UserFilter, UserInvite } from 'types/user';
import { IVerificationCode } from 'redux/slices/auth';

class ApiClient {
  setToken = (access: string, refresh?: string) => {
    AxiosClient.defaults.headers.common.Authorization = `Bearer ${access}`;
  };

  // account
  logOut = () => AxiosClient.get('/auth/logout');

  logIn = ({ email, password }: { email: string; password: string }) =>
    AxiosClient.post('/auth/login', { email, password });

  signUp = ({
    email,
    firstName,
    lastName,
    password,
    code,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    code: string;
  }) =>
    AxiosClient.post('/admin/users/registration-user', {
      email,
      firstName,
      lastName,
      password,
      code,
      isVirtual: false
    });

  refreshUserToken = (refreshToken: string) =>
    AxiosClientNotAuth.post(
      '/auth/new-tokens',
      {},
      {
        headers: {
          'Refresh-Auth': refreshToken,
        },
      },
    );

  forgotPassword = (forgotPasswordParams: {email: string}) => {
    return AxiosClient.post(`/auth/forgot-password`, forgotPasswordParams);
  }

  recoverPassword = ({password, token}: {password: string, token: string}) => {
    return AxiosClient.post(`/auth/password-reset`,
      {password},
      {headers: {
        "Email-Auth": token
      }}
    )
  }

  checkLink = (hash: string) => {
    return AxiosClient.get(`invite-links/${hash}`);
  }

  // orders
  fetchOrders = ({
    offset,
    limit,
    search,
    property,
    type,
    filter,
  }: {
    offset: number;
    limit: number;
    search?: string;
    property?: string;
    type?: string;
    filter?: OrderFilterQueryProps;
  }) => {
    let searchSortParams = '';
    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    if (filter) {
      for (let key in filter) {
        // @ts-ignore
        if (!!filter[key]) {
          // @ts-ignore
          searchSortParams = searchSortParams + `&${key}=${filter[key]}`;
        }
      }
    }
    return AxiosClient.get(`/admin/orders?offset=${offset}&limit=${limit}${searchSortParams}`);
  };
  fetchOrderItem = (id: number) => AxiosClient.get(`/admin/orders/${id}`);
  fetchOrderItemLogs = (id: number, offset: number, limit: number) =>
    AxiosClient.get(`/admin/orders/${id}/change-log?offset=${offset}&limit=${limit}`);
  changeStatusOrder = (id: number, status: string) =>
    AxiosClient.post('/admin/orders/status', [
      {
        id,
        status,
      },
    ]);

  editOrder = (id: number, order: OrderEditingProps) => AxiosClient.patch(`/admin/orders/${id}`, [order]);
  editOrderItemEndDate = ({ id, endDate, availability }: { id: number; endDate: string; availability: number }) =>
    AxiosClient.patch(`/admin/orders/${id}/change-end-date`, { endDate, availability });
  deleteOrder = (id: number) => AxiosClient.delete('/admin/orders', { data: { ids: [id] } });
  createOrder = (orderForm: OrderCreateDto) => AxiosClient.post('/admin/orders', orderForm);

  // common
  fetchLevels = () => AxiosClient.get('/levels');
  fetchCompanies = () => AxiosClient.get('/companies');
  fetchLanguages = () => AxiosClient.get('/languages');
  fetchCategories = () => AxiosClient.get('/categories');
  fetchWeeklyBillingsStatuses = () => AxiosClient.get('/enums/weekly-billings-status');
  fetchOrdersStatuses = () => AxiosClient.get('/enums/order-status');

  fetchPayoutsStatuses = () => AxiosClient.get('/enums/agency-payouts-status');
  fetchPurchaseOrderInvoiceStatuses = () => AxiosClient.get('/enums/purchase-order-invoice-status');
  fetchPurchaseOrderStatuses = () => AxiosClient.get('/enums/purchase-order-status');
  fetchBusinessRuleAppliesTo = () => AxiosClient.get('/enums/business-rule-applies-to');
  fetchOrderChangeTypes = () => AxiosClient.get('/enums/order-change-types');
  fetchProductActionTypes = () => AxiosClient.get('/enums/product-action-types');
  fetchAgencyActionTypes = () => AxiosClient.get('/enums/agency-action-types');
  fetchCustomerChangeTypes = () => AxiosClient.get('/enums/customer-change-types');
  fetchSpecialistChangeTypes = () => AxiosClient.get('/enums/specialist-change-types');
  fetchBusinessRuleFields = (appliesTo: string, changeType: string) =>
    AxiosClient.get('/enums/business-rule-fields/' + appliesTo + '/' + changeType);
  fetchBusinessRuleActionTypes = (appliesTo: string, changeType: string) =>
    AxiosClient.get('/enums/business-rule-action-types/' + appliesTo + '/' + changeType);

  // consultants
  fetchConsultants = ({
    offset,
    limit,
    property,
    type,
    search,
    filter,
  }: {
    offset: number;
    limit: number;
    property?: string;
    type?: string;
    search?: string;
    filter?: ConsultantFilterQueryProps;
  }) => {
    let searchSortParams = '';
    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${encodeURIComponent(search)}`;
    }
    return AxiosClient.get(`/admin/consultants?offset=${offset}&limit=${limit}${searchSortParams}`, { params: filter });
  };
  fetchConsultantItem = (id: number) => AxiosClient.get(`/admin/consultants/${id}`);
  fetchCv = (id: number) => AxiosClient.get(`/admin/consultants/cv-file/${id}`);
  updateConsultantItem = (id: number, params: any) => AxiosClient.patch(`/admin/consultants/${id}`, params);
  fetchConsultantItemAvailability = (id: number) => AxiosClient.get(`/consultant/${id}/availablity`);
  fetchConsultantItemProducts = (userId: number, offset: number, limit: number) =>
    AxiosClient.get(`/consultant-products/${userId}/full?offset=${offset}&limit=${limit}`);
  fetchConsultantItemLanguages = (userId: number, offset: number, limit: number) =>
    AxiosClient.get(`/consultant/${userId}/languages?offset=${offset}&limit=${limit}`);
  approveConsultant = (id: number) => AxiosClient.post('/admin/consultants/approve', { ids: [id] });
  declineConsultant = (id: number) => AxiosClient.post('/admin/consultants/decline', { ids: [id] });
  markInternalConsultant = ({ id, isInternalConsultant }: { id: number; isInternalConsultant: boolean }) =>
    AxiosClient.patch('/admin/consultants/mark-internal', {
      userId: id,
      isInternalConsultant,
    });
  changeConsultantActiveStatus = (id: number) => AxiosClient.post(`/admin/consultants/${id}/deactive`);
  changeConsultantCallScheduledStatus = (id: number) => AxiosClient.post(`/admin/consultants/${id}/call-scheduled`);
  changeConsultantEmail = ({ id, email }: { id: number; email: string }) =>
    AxiosClient.post(`/admin/consultants/${id}/change-email`, { email });

  fetchConsultantsSummaryData = () => AxiosClient.get('/admin/consultants/summary-data');

  fetchApprovedConsultantsSummaryData = () => AxiosClient.get('/admin/consultants/approved-specialists-statistic-data');

  fetchConsultantHashCode = (id: number) => AxiosClient.get(`/admin/consultants/${id}/uid-hash-code`);

  // reviews
  fetchReviews = ({
    offset,
    limit,
    property,
    type,
  }: {
    offset: number;
    limit: number;
    property?: string;
    type?: string;
  }) => {
    let searchSortParams = '';
    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    return AxiosClient.get(`/admin/orders/feedbacks?offset=${offset}&limit=${limit}${searchSortParams}`);
  };
  fetchReviewItem = (id: number) => AxiosClient.get(`/admin/orders/${id}/feedbacks`);
  approveReviewItem = (id: number) => AxiosClient.patch(`/admin/orders/${id}/feedbacks/approve`);
  declineReviewItem = (id: number) => AxiosClient.patch(`/admin/orders/${id}/feedbacks/decline`);

  // customers
  fetchCustomers = ({
    offset,
    limit,
    property,
    type,
    search,
    isActive,
  }: {
    offset: number;
    limit: number;
    property?: string;
    type?: string;
    search?: string;
    isActive?: boolean;
  }) => {
    let searchSortParams = '';
    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    return AxiosClient.get(`/admin/customers?offset=${offset}&limit=${limit}${searchSortParams}`, {params: {isActive}});
  };
  fetchExportData = ({
    dataFormat,
    entity
  }: {
    dataFormat: ExportType
    entity: String
  }) => {
    return AxiosClient.get(`/admin/${entity}/download?dataFormat=${dataFormat}`, {responseType: 'blob'});
  };
  changeCustomersActiveStatus = (id: number) => AxiosClient.post(`/admin/customers/${id}/deactive`);
  fetchImpersonateAccessToken = (userId: number) => {
    return AxiosClient.get(`/admin/auth/${userId}/access-token`);
  };
  fetchAgencyImpersonateAccessTokenByUserId = (agencyId: number, userId: number) => {
    return AxiosClient.get(`/admin/agency/${agencyId}/users-team/user/${userId}`);
  };
  changeCustomerEmail = ({ id, email }: { id: number; email: string }) =>
    AxiosClient.post(`/admin/customers/${id}/change-email`, { email });

  // tags
  fetchTags = (offset: number, limit: number) => {
    return AxiosClient.get(`/admin/tags?offset=${offset}&limit=${limit}`);
  };

  addTagItem = (tag: TagsCreationProps) => {
    return AxiosClient.post('/admin/tags', [tag]);
  };

  editTagItem = (tag: TagProps) => {
    return AxiosClient.patch('/admin/tags', [tag]);
  };

  // companies
  fetchCompaniesList = ({
    offset,
    limit,
    search,
    isEnterprise,
    property,
    type,
  }: {
    offset: number;
    limit: number;
    search?: string;
    isEnterprise?: boolean;
    property?: string;
    type?: string;
  }) => {
    let searchSortParams = '';

    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    if (isEnterprise) {
      searchSortParams = searchSortParams + `&isEnterprise=${isEnterprise}`;
    }
    return AxiosClient.get(`/admin/companies?offset=${offset}&limit=${limit}${searchSortParams}`);
  };
  fetchCompanyItem = (id: number) => AxiosClient.get(`/admin/companies/${id}`);
  addCompanyItem = (company: CompanyCreationProps) => {
    return AxiosClient.post('/admin/companies', [company]);
  };
  editCompanyItem = (company: CompanyCreationProps) => {
    return AxiosClient.patch('/admin/companies', [company]);
  };
  addCompanyItemLogo = (companyId: number, data: FormData) => {
    return AxiosClient.post(`/admin/companies/${companyId}/logo`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };
  editCompanyItemEnterpriseStatus = (company: CompanyEnterpriseEditingProps) => {
    return AxiosClient.patch('/admin/companies/enterprise', {
      data: [company],
    });
  };
  changeCompanyTeamActiveStatus = (id: number) =>
    AxiosClient.post(`/admin/companies/enterprise/${id}/deactive-all-users`);
  changeCompanyEmail = ({ id, email }: { id: number; email: string }) =>
    AxiosClient.post(`/admin/companies/enterprise/${id}/change-owner-email`, { email });

  // agencies
  fetchAgenciesList = ({
    offset,
    limit,
    search,
    isEnterprise,
    property,
    type,
    isActive,
  }: {
    offset: number;
    limit: number;
    search?: string;
    isEnterprise?: boolean;
    property?: string;
    type?: string;
    isActive?: boolean;
  }) => {
    let searchSortParams = '';

    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    if (isEnterprise) {
      searchSortParams = searchSortParams + `&isEnterprise=${isEnterprise}`;
    }
    return AxiosClient.get(`/admin/agency?offset=${offset}&limit=${limit}${searchSortParams}`, {params: {isActive}});
  };

  fetchAgencyItem = (id: number) => {
    return AxiosClient.get(`/admin/agency/${id}`)
  }

  addAgencyItem = (agency: AgencyCreationProps) => {
    return AxiosClient.post('/admin/agency', agency);
  };

  editAgencyItem = (agency: AgencyEnterpriseEditingProps) => {
    const logoData = new FormData();
    if (agency?.logo) {
      const { blob: logo_image } = dataToImgFile(agency.logo);
      logoData.append('logo_image', logo_image as Blob, 'logo.jpg');
    }
    return AxiosClient.patch('/admin/agency', agency);
  };

  addAgencyItemLogo = (agencyId: number, data: FormData) => {
    return AxiosClient.post(`/admin/agency/${agencyId}/logo`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  changeAgencyTeamActiveStatus = (id: number) => AxiosClient.post(`/admin/agency/${id}/users-team/deactive-all-users`);

  // feedbacks
  fetchFeedbacksList = ({
    offset,
    limit,
    search,
    property,
    type,
  }: {
    offset: number;
    limit: number;
    search?: string;
    property?: string;
    type?: string;
  }) => {
    let searchSortParams = '';
    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    return AxiosClient.get(`/admin/feedbacks?offset=${offset}&limit=${limit}${searchSortParams}`);
  };

  fetchFeedbackItem = (id: number) => AxiosClient.get(`/admin/feedbacks/${id}`);

  editFeedbackItem = (feedback: FeedbackProps) => {
    return AxiosClient.patch('/admin/feedbacks', [feedback]);
  };

  // products
  fetchProductsList = ({
    offset,
    limit,
    search: nameStartWith,
    orderByField,
    orderBySequence,
    filter,
  }: {
    offset?: number,
    limit?: number,
    search?: string,
    orderByField?: string,
    orderBySequence?: "DESC" | "ASC",
    filter?: ProductsFilterQueryProps,
  }) => {
    let searchSortParams = '';
    if (nameStartWith) {
      searchSortParams = searchSortParams + `&nameStartWith=${nameStartWith}`;
    }
    if (orderByField) {
      searchSortParams = searchSortParams + `$orderByField=${orderByField}`;
    }
    if (filter) {
      for (let key in filter) {
        // @ts-ignore
        if (!!filter[key]) {
          // @ts-ignore
          searchSortParams = searchSortParams + `&${key}=${filter[key]}`;
        }
      }
    }
    return AxiosClient.get(`/admin/products/filter?offset=${offset}&limit=${limit}&orderBySequence=${orderBySequence}${searchSortParams}`);
    // return AxiosClient.post(`/admin/products/filter`, { ...productsFilter });
  };

  fetchProductAvailability = () => {
    return AxiosClient.get(`/admin/products/product-availability`);
  };

  fetchProductItem = (id: number) => AxiosClient.get(`/admin/products/${id}`);

  editProductItem = (product: ProductEditingProps) => {
    return AxiosClient.patch('/admin/products', [product]);
  };
  updateWorkTypes = (workType: UpdateWorkTypeProps) => {
    return AxiosClient.patch('/admin/product-works', workType);
  };
  createWorkTypeItem = (workType: AddWorkTypeProps) => {
    return AxiosClient.post('/admin/product-works', [workType]);
  };
  updateProductModules = (productModule: UpdateProductModulesProps) => {
    return AxiosClient.patch('/admin/product-modules', productModule);
  };
  createProductModulesItem = (productModule: AddProductModulesProps) => {
    return AxiosClient.post('/admin/product-modules', [productModule]);
  };
  createProductItem = (product: ProductCreatingProps) => {
    return AxiosClient.post('/admin/products', [product]);
  };

  fetchProductsSummaryData = ({ offset, limit }: { offset: number, limit: number }) =>
    AxiosClient.get(`/admin/products/summary-data?offset=${offset}&limit=${limit}`);

  // purchase orders / Order Forms
  fetchPurchaseOrdersList = ({
    offset,
    limit,
    companyId,
    property,
    type,
    search,
    filter,
  }: {
    offset: number;
    limit: number;
    companyId?: number;
    property?: string;
    type?: OrderingType;
    search?: string;
    filter?: PurchaseOrderFilterQueryProps;
  }) => {
    let searchSortParams = '';
    if (property) {
      searchSortParams = searchSortParams + `&property=${property}`;
    }
    if (type) {
      searchSortParams = searchSortParams + `&type=${type}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    if (companyId) {
      searchSortParams = searchSortParams + `&companyId=${companyId}`;
    }
    if (filter) {
      for (let key in filter) {
        // @ts-ignore
        if (!!filter[key]) {
          // @ts-ignore
          searchSortParams = searchSortParams + `&${key}=${filter[key]}`;
        }
      }
    }
    return AxiosClient.get(`/admin/purchase-orders?offset=${offset}&limit=${limit}${searchSortParams}`);
  };

  addPurchaseOrderItem = (purchaseOrder: PurchaseOrderCreation) => {
    return AxiosClient.post('/admin/purchase-orders', purchaseOrder);
  };

  updatePurchaseOrderItem = (purchaseOrder: PurchaseOrderEdition) => {
    return AxiosClient.patch('/admin/purchase-orders', purchaseOrder);
  };

  uploadPurchaseOrderFile = (purchaseOrder: FormData, headers: AxiosRequestConfig) => {
    return AxiosClient.post('/admin/purchase-orders/private-files', purchaseOrder, headers);
  };

  downloadPurchaseOrderFile = (link: string) => {
    return AxiosClient.get(`${link}`);
  };

  getPurchaseOrderFileById = (id: number) => {
    return AxiosClient.get(`/admin/purchase-orders/private-files/${id}`);
  };

  getPurchaseOrderArchieve = (id: number) => {
    return AxiosClient.get(`/admin/purchase-orders/${id}/private-files/download`, { responseType: "arraybuffer" } );
  };

  // Enterprise
  fetchWeeklyBillingsList = ({ offset, limit }: { offset: number; limit: number }) => {
    return AxiosClient.get(`/admin/weekly-billings?offset=${offset}&limit=${limit}`);
  };

  fetchWeeklyBillingsStat = () => {
    return AxiosClient.get(`/admin/weekly-billings/stats`);
  };

  fetchWeeklyBillingsFilteredList = (weeklyBillingsFilter: WeeklyBillingsFilterProps) => {
    return AxiosClient.post(`/admin/weekly-billings/filter`, {
      ...weeklyBillingsFilter,
    });
  };

  approveWeeklyBillingItem = (id: number) => {
    return AxiosClient.post(`/admin/weekly-billings/${id}/approve`);
  };

  disputeWeeklyBillingItem = ({ id, text }: { id: number; text: string }) => {
    return AxiosClient.post(`/admin/weekly-billings/${id}/disputed`, { text });
  };

  fetchInvoicesList = (invoicesFilter: InvoicesFilterProps) => {
    return AxiosClient.post(`/admin/purchase-order-invoice/filter`, {
      ...invoicesFilter,
    });
  };

  addInvoiceItem = (invoice: InvoiceCreationProps) => {
    return AxiosClient.post('/admin/purchase-order-invoice', invoice);
  };

  fetchInvoiceItem = (id: number) => {
    return AxiosClient.get(`/admin/purchase-order-invoice/${id}`);
  };

  editInvoiceItem = (invoice: InvoiceEditingProps) => {
    return AxiosClient.patch(`/admin/purchase-order-invoice`, { ...invoice });
  };

  sendViaStripeInvoiceItem = (id: number) => {
    return AxiosClient.post(`/admin/purchase-order-invoice/${id}/send-to-stripe`);
  };

  // Payouts
  fetchPayoutsList = (payoutsFilterProps: PayoutsFilterProps) => {
    const { offset, limit, ...rest } = payoutsFilterProps;
    let searchSortParams = '';
    for (let key in rest) {
      // @ts-ignore
      if (!!rest[key]) {
        // @ts-ignore
        searchSortParams = searchSortParams + `&${key}=${rest[key]}`;
      }
    }
    return AxiosClient.post(`/admin/agency/payouts`, payoutsFilterProps);
  };

  transferPayoutItem = (id: number) => {
    return AxiosClient.post('/admin/agency/payouts/transfer', { id, stripeTransfers: true });
  };

  createPayout = (createProps: PayoutCreateDto) => {
    return AxiosClient.post('/admin/agency/payouts/create', createProps);
  }

  updatePayout = (updateProps: PayloadUpdateDto) => {
    return AxiosClient.patch('/admin/agency/payouts/update', updateProps);
  }

  // Leads
  fetchLeads = (offset: number, limit: number) => {
    return AxiosClient.get(`/admin/leads?offset=${offset}&limit=${limit}`);
  };

  fetchLeadItem = (id: number) => {
    return AxiosClient.get(`/admin/leads/${id}`);
  };

  addLeadItem = (lead: LeadCreationProps) => {
    return AxiosClient.post('/admin/leads', [lead]);
  };

  fetchLeadManagers = () => {
    return AxiosClient.get('/admin/lead-managers');
  };

  fetchLeadManagerItem = (id: number) => {
    return AxiosClient.get(`/admin/lead-managers/${id}`);
  };

  createLeadManagerItem = (leadManager: LeadManagerCreateProps) => {
    return AxiosClient.post(`/admin/lead-managers/invite-manager`, leadManager);
  };

  deleteLeadManagerItem = (id: number) => {
    return AxiosClient.delete('/admin/lead-managers/', { data: { ids: [id] } });
  };

  createPreOrderFromLead = (preOrder: PreOrderCreateProps) => {
    return AxiosClient.post(`admin/leads/create-pre-order`, [preOrder]);
  };

  fetchLeadAgencies = (leadSourceId: number, productId: number, workTypeId: number) => {
    return AxiosClient.get(`/admin/leads/agency-candidates/${leadSourceId}/${productId}/${workTypeId}`);
  };

  // Service Catalog Entries
  fetchServiceCatalogEntries = (offset: number, limit: number) => {
    return AxiosClient.get(`/admin/service-catalog-entries?offset=${offset}&limit=${limit}`);
  };

  fetchServiceCatalogEntry = (id: number) => {
    return AxiosClient.get(`/admin/service-catalog-entries/${id}`);
  };

  fetchServiceCatalogEntriesFromLeadId = (leadId: string) => {
    return AxiosClient.get(`admin/ai/lead-to-service-catalog-entry?leadId=${leadId}`, {
      timeout: 60000, // 60 seconds timeout
    });
  };

  createServiceCatalogEntry = (serviceCatalogEntry: ServiceCatalogEntryCreateProps) => {
    return AxiosClient.post('/admin/service-catalog-entries', serviceCatalogEntry);
  };

  deleteServiceCatalogEntry = (id: number) => {
    return AxiosClient.delete(`/admin/service-catalog-entries/${id}`);
  };

  updateLeadManagerItem = (leadManager: LeadManagerUpdateProps) => {
    return AxiosClient.patch('/admin/lead-managers', [leadManager]);
  };

  fetchLeadSources = () => {
    return AxiosClient.get('/admin/lead-source');
  };

  addLeadSource = (leadSource: CreateLeadSourceProps) => {
    return AxiosClient.post('/admin/lead-source', leadSource);
  };

  updateLeadSource = (leadSource: CreateLeadSourceProps) => {
    return AxiosClient.post('/admin/lead-source', leadSource);
  };

  // business rules
  fetchBusinessRulesList = (
    offset: number,
    limit: number,
    appliesTo?: string,
    changeType?: string,
    search?: string,
  ) => {
    let searchSortParams = '';
    if (appliesTo) {
      searchSortParams = searchSortParams + `&appliesTo=${appliesTo}`;
    }
    if (changeType) {
      searchSortParams = searchSortParams + `&changeType=${changeType}`;
    }
    if (search) {
      searchSortParams = searchSortParams + `&search=${search}`;
    }
    return AxiosClient.get(`/admin/business-rules?offset=${offset}&limit=${limit}${searchSortParams}`);
  };

  addBusinessRuleItem = (businessRule: BusinessRuleCreation) => {
    return AxiosClient.post('/admin/business-rules', businessRule);
  };

  updateBusinessRuleItem = (businessRule: BusinessRuleEdition) => {
    return AxiosClient.patch('/admin/business-rules', businessRule);
  };

  updateBusinessRuleAction = (businessRule: BusinessRuleEdition, businessRuleAction: BusinessRuleAction) => {
    return AxiosClient.patch(`/admin/business-rules/${businessRule.id}/action`, {
      action: businessRuleAction,
    });
  };

  deleteBusinessRuleItem = (businessRule: BusinessRuleEdition) => {
    return AxiosClient.delete(`/admin/business-rules/${businessRule.id}`);
  };

  deleteBusinessRuleAction = (businessRule: BusinessRuleEdition, businessRuleAction: BusinessRuleAction) => {
    return AxiosClient.delete(`/admin/business-rules/${businessRule.id}/action/${businessRuleAction.id}`);
  };

  // users
  fetchUserList = (
    offset: number,
    limit: number,
    property?: string,
    type?: "desc" | "asc",
    filter?: UserFilter,
  ) => {
    return AxiosClient.get(`/admin/users?offset=${offset}&limit=${limit}`, {params: {...filter, type, property}});
  }

  addUser = (userInvite: UserInvite) => {
    return AxiosClient.post(`/admin/users/invite-user`, {data: [userInvite]});
  }

  removeUser = (userIds: number[]) => {
    return AxiosClient.post(`/admin/users/delete-users`, {data: [...userIds.map((id) => ({userId: id}))]});
  }

  deactivateUser = (ids: number[]) => {
    return AxiosClient.post(`/admin/users/deactive-users`, {data: [...ids.map((id) => ({userId: id}))]});
  }

  resendUserInvite = (userInvite: UserInvite) => {
    return AxiosClient.post(`/admin/users/resend-invite-user`, {data: [userInvite]})
  }

  verificationCode = ({code, email}: IVerificationCode) => {
    return AxiosClient.post('auth/confirm', {}, { headers: {
        'Confirm-Code': code,
        'Email': email,
    }});
  }
}

export default new ApiClient();
