// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
};

export const ROUTES = {
  HOME: '/',
  ORDERS: '/orders',
  ORDER_ITEM: '/orders/:id',
  ORDER_MANAGE: '/orders/manage',
  ORDER_CREATE_EDIT: '/orders/manage/:id',
  REVIEWS: '/reviews',
  REVIEW_ITEM: '/reviews/:id',
  CONSULTANTS: '/consultants',
  CONSULTANT_ITEM: '/consultants/:id',
  CUSTOMERS: '/customers',
  TAGS: '/tags',
  COMPANIES: '/companies',
  AGENCIES: '/agencies',
  FEEDBACKS: '/feedbacks',
  FEEDBACK_ITEM: '/feedbacks/:id',
  PRODUCTS: '/products',
  PRODUCT_ITEM: '/products/:id',
  ORDER_FORMS: '/order-forms',
  WEEKLY_BILLINGS: '/weekly-billings',
  ENTERPRISE_INVOICING: '/enterprise-invoicing',
  AGENCY_PAYOUTS: '/agency-payouts',
  LEADS: '/leads',
  LEAD_ITEM: '/leads/:id',
  LEAD_MANAGERS: '/lead-managers',
  SERVICE_CATALOG_ENTRIES: '/service-catalog-entries',
  SERVICE_CATALOG_ENTRY: '/service-catalog-entries:id',
  BUSINESS_RULES: '/business-rules',
  LEAD_MANAGER_ITEM: '/lead-managers/:id',
  AUTH: '/auth',
  ACCOUNT: '/account',
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  RESET_PASSWORD: '/auth/reset-password',
  CODE: '/auth/verification',
  RECOVER_PASSWORD: '/auth/recover-password',
  USERS: '/users',
};

export const enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
};

export const MAX_AVATAR_IMAGE_SIZE = 5242880; // 5 Mb

export const ENTERPRISE_TABS = {
  WEEKLY_BILLINGS: 'weekly-billings',
  INVOICES: 'Invoices',
};

export const AGENCY_PAYOUT_TABS = {
  PAYOUTS: 'agency-payouts',
  WEEKLY_BILLINGS: 'weekly-billings',
}

export const MONTHLY_PAYMENT_TERMS = [{
  value: 30,
  name: '30 days (1.25% fee)',
}, {
  value: 45,
  name: '45 days (1.5% fee)',
}, {
  value: 60,
  name: '60 days (1.75% fee)',
}];

export const enum ExportType {
  CSV = 'csv',
  XLSX = 'xlsx'
}

export const EXPORT_TYPES = [{
  label: 'Download CSV',
  type: ExportType.CSV
},{
  label: 'Download Excel',
  type: ExportType.XLSX
}]