import React from 'react';
import {Typography} from "@mui/material";
import VerificationComponent from "../../components/auth/VerificationCode";
import { Wrapper } from 'components/auth/styled';

const VerificationCode = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Verify account
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          We just sent you a verification code to your email.
          <br />
          Please enter the verification code below.
        </Typography>

        <VerificationComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default VerificationCode;
