import React from "react";
import {useRoutes} from "react-router-dom";
import {create} from "jss";
import {ThemeProvider} from "styled-components/macro";

import {StyledEngineProvider} from "@mui/styled-engine-sc";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";

// @ts-ignore
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const content = useRoutes(routes);
  const {theme} = useTheme();


  return (
    <StylesProvider jss={jss}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={createTheme(theme)}>
            <ThemeProvider theme={createTheme(theme)}>
              {content}
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </StylesProvider>
  );
}

export default App;
