import React from 'react';
import {Typography} from "@mui/material";
import RecoverPasswordComponent from "../../components/auth/RecoverPassword";
import { Wrapper } from 'components/auth/styled';

const RecoverPassword = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Create a new password
        </Typography>

        <RecoverPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default RecoverPassword;
