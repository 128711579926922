import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { ROUTES } from '../../constants';
import { setSnackNotification } from "redux/slices/snackNotifications";
import { ESeverity } from "types/common";
import { useAppDispatch } from "redux/hooks";
import { forgotPassword } from "redux/slices/auth";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit =  (values: any) => {
    dispatch(forgotPassword({
        email: values.email,
      }))
      .unwrap()
      .then(() => {
        navigate(ROUTES.CODE, { state: {email: values.email}});
      })
      .catch((err) => {
        dispatch(setSnackNotification({
          isOpen: true,
          severity: ESeverity.ERROR,
          message: err.message
        }));
      })
  }

  return (
    <>
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255, "Email must be at most 255 characters")
          .required("Email is required"),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        dirty,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty}
          >
            Reset password
          </Button>
        </form>
      )}
    </Formik>
    <Box mt={3} display="flex" justifyContent="center">
      <Link to={`${ROUTES.SIGN_IN}`}>Sign in</Link>
    </Box>
    </>
  );
}

export default ResetPassword;
