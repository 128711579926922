export enum ROLE {
  CONSULTANT = 'consultant',
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  ACCOUNT_ADMIN = 'account_admin',
  AGENCY_ADMIN = 'agency_admin',
  REGULAR_USER = 'regular_user',
  RESOURCE_MANAGER = 'agency_resource_manager',
  LEAD_MANAGER = 'lead_manager',
  OPERATIONS = 'operations',
  SALES = 'sales',
  TALENT = 'talent',
}
