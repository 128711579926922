import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import { ThemeProvider } from "./contexts/ThemeContext";
import { setupResponseInterceptor } from './api/AxiosClient';
import { store, persistor } from './redux/store';

setupResponseInterceptor(store);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
