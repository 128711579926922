import * as React from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from '../../redux/hooks';
import { authenticationSelector, initializingSelector } from '../../redux/selectors/getAuthData';
import { ROUTES } from '../../constants';
import { ROLE } from "../../models/const";
import { getUserSelector } from "../../redux/selectors/getAuthData";

interface AuthGuardType {
  children: React.ReactNode;
  allowedRoles?: ROLE[];
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, allowedRoles = [] }: AuthGuardType) {
  const isAuthenticated = useAppSelector(authenticationSelector);
  const isInitialized = useAppSelector(initializingSelector);
  const currentUser = useAppSelector(getUserSelector);

  if (isInitialized && !isAuthenticated) {
    return <Navigate to={ROUTES.SIGN_IN} />;
  }

  if (allowedRoles.includes(currentUser.role.name)) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <React.Fragment><div>Not Authorized</div></React.Fragment>;
}

export default AuthGuard;
