import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import {
  REDUCER_KEY_AGENCY_PAYOUTS,
  ACTION_NAME_FETCH_PAYOUTS_LIST,
  ACTION_NAME_TRANSFER_PAYOUT_ITEM,
  ACTION_NAME_CREATE_PAYOUT,
  ACTION_NAME_UPDATE_PAYOUT,
} from '../constants'
import ApiClient from '../../api/ApiClient';

import {AgencyPayout, PayloadUpdateDto, PayoutCreateDto, PayoutsFilterProps} from '../../types/payouts';
import { ActionSaveProps } from 'types/common';
import { ETransferRequestStatus } from 'pages/AgencyPayouts/consts';
import { changeWeeklyBillingTransferStatus } from './enterpriseInvoicing';

export interface PayoutsState {
  isFetching: boolean;
  payouts: AgencyPayout[];
  countPayouts: number;
  page: number;
  currentPayout?: AgencyPayout;
}

const initialState: PayoutsState = {
  isFetching: false,
  payouts: [],
  countPayouts: 0,
  page: 0,
}

const fetchPayoutsList = createAsyncThunk<AxiosResponse<any>, PayoutsFilterProps & ActionSaveProps>(
  `${REDUCER_KEY_AGENCY_PAYOUTS}/${ACTION_NAME_FETCH_PAYOUTS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    orderByField,
    orderBySequence = "ASC",
    agencyIds,
    status,
    ids,
    from,
    to,
  }) => {
    const response = await ApiClient.fetchPayoutsList({
      offset,
      limit,
      orderByField,
      orderBySequence,
      agencyIds,
      ids,
      status,
      from,
      to,
    });
    return response;
  },
);

const transferPayoutItem = createAsyncThunk<AxiosResponse<any>, number>(
  `${REDUCER_KEY_AGENCY_PAYOUTS}/${ACTION_NAME_TRANSFER_PAYOUT_ITEM}`,
  async (payoutId) => {

    const response = await ApiClient.transferPayoutItem(payoutId);
    return response;
  },
);

const createPayout = createAsyncThunk<AxiosResponse<any>, PayoutCreateDto>(
  `${REDUCER_KEY_AGENCY_PAYOUTS}/${ACTION_NAME_CREATE_PAYOUT}`,
  async (createPayout, thunkApi) => {
    const response = await ApiClient.createPayout(createPayout);
    await thunkApi.dispatch(changeWeeklyBillingTransferStatus({
      weeklyBillingIds: createPayout.weeklyBillingsIds,
      status: ETransferRequestStatus.AGENCY_PROCESSING_TASK
    }))
    return response;
  }
)

const updatePayout = createAsyncThunk<AxiosResponse<any>, PayloadUpdateDto>(
  `${REDUCER_KEY_AGENCY_PAYOUTS}/${ACTION_NAME_UPDATE_PAYOUT}`,
  async (updatePayout) => {
    const response = await ApiClient.updatePayout(updatePayout);
    return response;
  }
)

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_AGENCY_PAYOUTS,
  initialState,
  reducers: {
    resetAgencyPayouts: (state) => {
      state.isFetching = false;
      state.payouts = [];
      state.countPayouts = 0;
      state.page = 0;
    },
    setCurrentPayout: (state, {payload}) => {
      state.currentPayout = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayoutsList.pending, (state, {meta}) => {
        if (!meta.arg.withoutSave) {
          state.isFetching = true;
        }
      })
      .addCase(fetchPayoutsList.rejected, (state, {meta}) => {
        if (!meta.arg.withoutSave) {
          state.isFetching = false;
        }
      })
      .addCase(fetchPayoutsList.fulfilled, (state, {payload, meta}) => {
        if (!meta.arg.withoutSave) {
          state.payouts = payload.data.agencyPayouts;
          state.countPayouts = payload.data.count;
          state.page = payload.data.page;
          state.isFetching = false;
        }
      })
      .addCase(createPayout.fulfilled, (state, {payload}) => {
        if (state.payouts) {
          state.payouts = [...state.payouts, payload.data];
          state.countPayouts = state.countPayouts + 1;
        }
      })
      .addCase(updatePayout.fulfilled, (state, {payload}) => {
        if (state.payouts) {
          state.payouts = state.payouts.map((payout) => {
            if (payload.data.id === payout.id) {
              return {
                ...payout,
                ...payload.data
              }
            }
            return payout;
          });
        }
        if (state.currentPayout && payload.data.id === state.currentPayout.id) {
          state.currentPayout = payload.data;
        }
      })
      .addCase(transferPayoutItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(transferPayoutItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(transferPayoutItem.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        state.payouts = state.payouts.map(item => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
      });
  },
});

const {
  resetAgencyPayouts,
  setCurrentPayout,
} = actions;

export {
  resetAgencyPayouts,
  setCurrentPayout,
  fetchPayoutsList,
  transferPayoutItem,
  createPayout,
  updatePayout,
};

export default reducer;
