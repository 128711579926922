import React from 'react';
import {Typography} from "@mui/material";
import ResetPasswordComponent from "../../components/auth/ResetPassword";
import { Wrapper } from 'components/auth/styled';

const ResetPassword = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default ResetPassword;
