import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { checkLink, signUp } from "redux/slices/auth";
import { useAppDispatch } from "redux/hooks";
import { setSnackNotification } from "redux/slices/snackNotifications";
import { ESeverity } from "types/common";
import { ROUTES } from "../../constants";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().max(255).required("First name is required"),
  lastName: Yup.string().max(255).required("Last name is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .max(255, "Password must be at most 255 characters")
    .required("Required"),
  confirmPassword: Yup.string().when("password", {
    is: (val: any) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  })
  .required("Required"),
})

const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();
  const [email, setEmail] = useState<string>("")

  useEffect(() => {
    if (hash) {
      dispatch(checkLink(hash))
      .unwrap()
      .then(({data}) => {
        setEmail(data.email);
      });
    }
  }, [dispatch, hash])

  const handleSubmit =  (values: any) => {
    if (hash) {
      dispatch(signUp({
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        code: hash
      }))
      .unwrap()
      .then(() => {
        navigate(ROUTES.HOME);
      })
      .catch((err) => {
        dispatch(setSnackNotification({
          isOpen: true,
          severity: ESeverity.ERROR,
          message: err.message
        }));
      })
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={RegisterSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              type="text"
              name="firstName"
              label="First name"
              inputProps={{
                autocomplete: 'off',
              }}
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="lastName"
              label="Last name"
              inputProps={{
                autocomplete: 'off',
              }}
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="email"
              label="Email"
              value={email}
              disabled
              fullWidth
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              inputProps={{
                autocomplete: 'off',
              }}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm password"
              inputProps={{
                autocomplete: 'off',
              }}
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isValid || !dirty}
            >
              Sign up
            </Button>
          </form>
        )}
      </Formik>
      <Box mt={3} display="flex" justifyContent="center">
        <Link to={`${ROUTES.SIGN_IN}`}>Sign in</Link>
      </Box>
    </>
  );
}

export default SignUp;
