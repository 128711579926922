import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_COMMON,
  ACTION_NAME_FETCH_LEVELS_LIST,
  ACTION_NAME_FETCH_COMMON_COMPANIES_LIST,
  ACTION_NAME_FETCH_COMMON_LANGUAGES_LIST,
  ACTION_NAME_FETCH_COMMON_WEEKLY_BILLINGS_STATUSES,
  ACTION_NAME_FETCH_COMMON_PURCHASE_ORDER_INVOICE_STATUSES,
  ACTION_NAME_FETCH_COMMON_PURCHASE_ORDER_STATUSES,
  ACTION_NAME_FETCH_COMMON_PAYOUTS_STATUSES,
  ACTION_NAME_FETCH_COMMON_ORDER_CHANGE_TYPES,
  ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_FIELDS,
  ACTION_NAME_FETCH_COMMON_ORDERS_STATUSES,
  ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_ACTION_TYPES,
  ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_APPLIES_TO,
  ACTION_NAME_FETCH_COMMON_SPECIALIST_CHANGE_TYPES,
  ACTION_NAME_FETCH_COMMON_PRODUCT_ACTIONS,
  ACTION_NAME_FETCH_COMMON_AGENCY_ACTIONS,
  ACTION_NAME_FETCH_COMMON_CUSTOMER_CHANGE_TYPES,
  ACTION_NAME_FETCH_COMMON_CATEGORIES_LIST,
} from '../constants';
import ApiClient from '../../api/ApiClient';
import { LanguageProps } from '../../types/language';
import { ICategory } from '../../types/products';

export interface CommonState {
  isFetching: boolean;
  levels: any[];
  companies: any[];
  languages: LanguageProps[];
  categories: ICategory[];
  weeklyBillingsStatuses: any[];
  ordersStatuses: any[];
  purchaseOrderInvoiceStatuses: any[];
  purchaseOrderStatuses: any[];
  payoutsStatuses: any[];
  businessRuleAppliesTo: any[];
  orderChangeTypes: any[];
  customerChangeTypes: any[];
  specialistChangeTypes: any[];
  productActions: any[];
  agencyActions: any[];
  businessRuleFields: any[];
  businessRuleActionTypes: any[];
}

const initialState: CommonState = {
  isFetching: false,
  levels: [],
  companies: [],
  languages: [],
  categories: [],
  weeklyBillingsStatuses: [],
  ordersStatuses: [],
  purchaseOrderInvoiceStatuses: [],
  purchaseOrderStatuses: [],
  payoutsStatuses: [],
  businessRuleAppliesTo: [],
  orderChangeTypes: [],
  customerChangeTypes: [],
  specialistChangeTypes: [],
  productActions: [],
  agencyActions: [],
  businessRuleFields: [],
  businessRuleActionTypes: [],
};

const fetchLevels = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_LEVELS_LIST}`,
  async () => {
    const response = await ApiClient.fetchLevels();
    return response;
  },
);

const fetchCompanies = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_COMPANIES_LIST}`,
  async () => {
    const response = await ApiClient.fetchCompanies();
    return response;
  },
);

const fetchLanguages = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_LANGUAGES_LIST}`,
  async () => {
    const response = await ApiClient.fetchLanguages();
    return response;
  },
);

const fetchCategories = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_CATEGORIES_LIST}`,
  async () => {
    const response = await ApiClient.fetchCategories();
    return response;
  },
);

const fetchWeeklyBillingsStatuses = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_WEEKLY_BILLINGS_STATUSES}`,
  async () => {
    const response = await ApiClient.fetchWeeklyBillingsStatuses();
    return response;
  },
);

const fetchOrdersStatuses = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_ORDERS_STATUSES}`,
  async () => {
    const response = await ApiClient.fetchOrdersStatuses();
    return response;
  },
);

const fetchPayoutsStatuses = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_PAYOUTS_STATUSES}`,
  async () => {
    const response = await ApiClient.fetchPayoutsStatuses();
    return response;
  },
);

const fetchPurchaseOrderInvoiceStatuses = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_PURCHASE_ORDER_INVOICE_STATUSES}`,
  async () => {
    const response = await ApiClient.fetchPurchaseOrderInvoiceStatuses();
    return response;
  },
);

const fetchPurchaseOrderStatuses = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_PURCHASE_ORDER_STATUSES}`,
  async () => {
    const response = await ApiClient.fetchPurchaseOrderStatuses();
    return response;
  },
);

const fetchBusinessRuleAppliesTo = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_APPLIES_TO}`,
  async () => {
    const response = await ApiClient.fetchBusinessRuleAppliesTo();
    return response;
  },
);

const fetchOrderChangeTypes = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_ORDER_CHANGE_TYPES}`,
  async () => {
    const response = await ApiClient.fetchOrderChangeTypes();
    return response;
  },
);

const fetchCustomerChangeTypes = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_CUSTOMER_CHANGE_TYPES}`,
  async () => {
    const response = await ApiClient.fetchCustomerChangeTypes();
    return response;
  },
);

const fetchSpecialistChangeTypes = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_SPECIALIST_CHANGE_TYPES}`,
  async () => {
    const response = await ApiClient.fetchSpecialistChangeTypes();
    return response;
  },
);

const fetchProductActionTypes = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_PRODUCT_ACTIONS}`,
  async () => {
    const response = await ApiClient.fetchProductActionTypes();
    return response;
  },
);

const fetchAgencyActionTypes = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_AGENCY_ACTIONS}`,
  async () => {
    const response = await ApiClient.fetchAgencyActionTypes();
    return response;
  },
);

const fetchBusinessRuleFields = createAsyncThunk<
  AxiosResponse<any>,
  {
    appliesTo: string;
    changeType: string;
  }
>(`${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_FIELDS}`, async ({ appliesTo, changeType }) => {
  const response = await ApiClient.fetchBusinessRuleFields(appliesTo, changeType);
  return response;
});

const fetchBusinessRuleActionTypes = createAsyncThunk<
  AxiosResponse<any>,
  {
    appliesTo: string;
    changeType: string;
  }
>(`${REDUCER_KEY_COMMON}/${ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_ACTION_TYPES}`, async ({ appliesTo, changeType }) => {
  const response = await ApiClient.fetchBusinessRuleActionTypes(appliesTo, changeType);
  return response;
});

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_COMMON,
  initialState,
  reducers: {
    resetCommon: (state) => {
      state.isFetching = false;
      state.levels = [];
      state.companies = [];
      state.languages = [];
      state.weeklyBillingsStatuses = [];
      state.purchaseOrderInvoiceStatuses = [];
      state.purchaseOrderStatuses = [];
      state.businessRuleAppliesTo = [];
      state.orderChangeTypes = [];
      state.customerChangeTypes = [];
      state.specialistChangeTypes = [];
      state.productActions = [];
      state.agencyActions = [];
      state.businessRuleFields = [];
      state.businessRuleActionTypes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevels.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLevels.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchLevels.fulfilled, (state, { payload }) => {
        state.levels = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchCompanies.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCompanies.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchCompanies.fulfilled, (state, { payload }) => {
        state.companies = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchLanguages.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLanguages.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchLanguages.fulfilled, (state, { payload }) => {
        state.languages = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.categories = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchWeeklyBillingsStatuses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchWeeklyBillingsStatuses.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchWeeklyBillingsStatuses.fulfilled, (state, { payload }) => {
        state.weeklyBillingsStatuses = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchOrdersStatuses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrdersStatuses.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchOrdersStatuses.fulfilled, (state, { payload }) => {
        state.ordersStatuses = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchPayoutsStatuses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPayoutsStatuses.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchPayoutsStatuses.fulfilled, (state, { payload }) => {
        state.payoutsStatuses = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchPurchaseOrderInvoiceStatuses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPurchaseOrderInvoiceStatuses.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchPurchaseOrderInvoiceStatuses.fulfilled, (state, { payload }) => {
        state.purchaseOrderInvoiceStatuses = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchPurchaseOrderStatuses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPurchaseOrderStatuses.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchPurchaseOrderStatuses.fulfilled, (state, { payload }) => {
        state.purchaseOrderStatuses = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchBusinessRuleAppliesTo.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBusinessRuleAppliesTo.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchBusinessRuleAppliesTo.fulfilled, (state, { payload }) => {
        state.businessRuleAppliesTo = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchOrderChangeTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrderChangeTypes.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchOrderChangeTypes.fulfilled, (state, { payload }) => {
        state.orderChangeTypes = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchCustomerChangeTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCustomerChangeTypes.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchCustomerChangeTypes.fulfilled, (state, { payload }) => {
        state.customerChangeTypes = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchSpecialistChangeTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchSpecialistChangeTypes.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchSpecialistChangeTypes.fulfilled, (state, { payload }) => {
        state.specialistChangeTypes = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchProductActionTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductActionTypes.fulfilled, (state, { payload }) => {
        state.productActions = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchProductActionTypes.rejected, (state) => {
        state.productActions = [];
        state.isFetching = false;
      })

      .addCase(fetchAgencyActionTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAgencyActionTypes.fulfilled, (state, { payload }) => {
        state.agencyActions = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchAgencyActionTypes.rejected, (state) => {
        state.agencyActions = [];
        state.isFetching = false;
      })

      .addCase(fetchBusinessRuleFields.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBusinessRuleFields.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchBusinessRuleFields.fulfilled, (state, { payload }) => {
        state.businessRuleFields = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchBusinessRuleActionTypes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBusinessRuleActionTypes.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchBusinessRuleActionTypes.fulfilled, (state, { payload }) => {
        state.businessRuleActionTypes = payload.data;
        state.isFetching = false;
      });
  },
});

const { resetCommon } = actions;

export {
  resetCommon,
  fetchLevels,
  fetchCompanies,
  fetchLanguages,
  fetchCategories,
  fetchWeeklyBillingsStatuses,
  fetchOrdersStatuses,
  fetchPurchaseOrderInvoiceStatuses,
  fetchPurchaseOrderStatuses,
  fetchPayoutsStatuses,
  fetchBusinessRuleAppliesTo,
  fetchOrderChangeTypes,
  fetchCustomerChangeTypes,
  fetchSpecialistChangeTypes,
  fetchProductActionTypes,
  fetchAgencyActionTypes,
  fetchBusinessRuleFields,
  fetchBusinessRuleActionTypes,
};

export default reducer;
