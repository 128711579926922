import {
  ShoppingCart,
  Users,
  FileText,
  Tag,
  Briefcase,
  Grid,
  DollarSign,
  Inbox, 
  Book,
  Shuffle,
  } from 'react-feather';

import { SidebarItemsType } from '../../types/sidebar';
import { ROUTES } from '../../constants';
import { ROLE } from '../../models/const';

const pagesSection = [
  {
    href: ROUTES.ORDERS,
    icon: ShoppingCart,
    title: "Orders",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.CONSULTANTS,
    icon: Users,
    title: "Consultants",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.FEEDBACKS,
    icon: FileText,
    title: "Feedbacks",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.REVIEWS,
    icon: FileText,
    title: "Reviews",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.CUSTOMERS,
    icon: Users,
    title: "Customers",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.TAGS,
    icon: Tag,
    title: "Tags",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.COMPANIES,
    icon: Briefcase,
    title: "Companies",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.AGENCIES,
    icon: Briefcase,
    title: "Agencies",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: `${ROUTES.PRODUCTS}?isActive=true`,
    icon: Grid,
    title: "Products",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.ORDER_FORMS,
    icon: DollarSign,
    title: "Order Forms",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES]
  },
  {
    href: ROUTES.WEEKLY_BILLINGS,
    icon: DollarSign,
    title: "Weekly Billings",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.ENTERPRISE_INVOICING,
    icon: DollarSign,
    title: "Enterprise Invoicing",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS]
  },
  {
    href: ROUTES.AGENCY_PAYOUTS,
    icon: DollarSign,
    title: "Agency Payouts",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS]
  },
  {
    href: ROUTES.LEADS,
    icon: Inbox,
    title: "Leads",
    allowedRoles: [ROLE.ADMIN, ROLE.LEAD_MANAGER]
  },
  {
    href: ROUTES.LEAD_MANAGERS,
    icon: Users,
    title: "Lead Managers",
    allowedRoles: [ROLE.ADMIN, ROLE.LEAD_MANAGER]
  },
  {
    href: ROUTES.SERVICE_CATALOG_ENTRIES,
    icon: Book,
    title: "Service Catalog",
    allowedRoles: [ROLE.ADMIN, ROLE.LEAD_MANAGER]
  },
  {
    href: ROUTES.BUSINESS_RULES,
    icon: Shuffle,
    title: "Business Rules",
    allowedRoles: [ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]
  },
  {
    href: ROUTES.USERS,
    icon: Users,
    title: "User Management",
    allowedRoles: [ROLE.ADMIN]
  }
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
