import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_ENTERPRISE_INVOICING,
  ACTION_NAME_FETCH_WEEKLY_BILLINGS_LIST,
  ACTION_NAME_FETCH_INVOICES_LIST,
  ACTION_NAME_ADD_INVOICE_ITEM,
  ACTION_NAME_FETCH_INVOICE_ITEM,
  ACTION_NAME_EDIT_INVOICE_ITEM,
  ACTION_NAME_APPROVE_WEEKLY_BILLING_ITEM,
  ACTION_NAME_DISPUTE_WEEKLY_BILLING_ITEM,
  ACTION_NAME_FETCH_WEEKLY_BILLINGS_FILTERED_LIST, ACTION_NAME_SEND_VIA_STRIPE_INVOICE_ITEM,
  ACTION_NAME_FETCH_WEEKLY_BILLINGS_STAT,
} from '../constants'
import ApiClient from '../../api/ApiClient';
import {
  Invoice,
  WeeklyBilling,
  InvoiceCreationProps,
  InvoiceEditingProps,
  WeeklyBillingStat,
} from '../../types/enterprise';
import { ActionSaveProps, OrderingType } from '../../types/common';

export interface EnterpriseInvoicingState {
  isFetching: boolean;
  weeklyBillings: WeeklyBilling[];
  countWeeklyBillings: number;
  invoices: Invoice[];
  countInvoices: number;
  page: number;
  isInvoiceFetching: boolean;
  currentInvoice?: Invoice;
  weeklyBillingsStat?: WeeklyBillingStat;
}

const initialState: EnterpriseInvoicingState = {
  isFetching: false,
  weeklyBillings: [],
  countWeeklyBillings: 0,
  invoices: [],
  countInvoices: 0,
  page: 0,
  isInvoiceFetching: false,
  currentInvoice: undefined,
}

const fetchWeeklyBillingsFilteredList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  orderByField?: string,
  orderBySequence?: OrderingType,
  orderIds?: number[],
  purchaseOrderIds?: number[],
  week?: {
    startDate: string,
    endDate: string,
  },
  status?: string | null,
  hours?: number,
  invoiceStatus?: string | null,
  ids?: number[],
  agencyIds?: number[],
}>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_FETCH_WEEKLY_BILLINGS_FILTERED_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    orderByField,
    orderBySequence= "DESC",
    status,
    hours,
    purchaseOrderIds,
    orderIds,
    week,
    invoiceStatus,
    ids,
    agencyIds,
  }) => {
    const response = await ApiClient.fetchWeeklyBillingsFilteredList({
      offset,
      limit,
      orderByField,
      orderBySequence,
      purchaseOrderIds,
      orderIds,
      status,
      hours,
      week,
      invoiceStatus,
      ids,
      agencyIds
    });
    return response;
  },
);

const fetchWeeklyBillingsList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
}>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_FETCH_WEEKLY_BILLINGS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
  }) => {
    const response = await ApiClient.fetchWeeklyBillingsList({
      offset,
      limit,
    });
    return response;
  },
);

const fetchWeeklyBillingsStat = createAsyncThunk<AxiosResponse<WeeklyBillingStat>>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_FETCH_WEEKLY_BILLINGS_STAT}`,
  async() => {
    const response = await ApiClient.fetchWeeklyBillingsStat();
    return response;
  }
)

const approveWeeklyBillingItem = createAsyncThunk<AxiosResponse<any>, number>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_APPROVE_WEEKLY_BILLING_ITEM}`,
  async (weeklyBillingId) => {

    const response = await ApiClient.approveWeeklyBillingItem(weeklyBillingId);
    return response;
  },
);

const disputeWeeklyBillingItem = createAsyncThunk<AxiosResponse<any>, { weeklyBillingId: number, text?: string }>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_DISPUTE_WEEKLY_BILLING_ITEM}`,
  async ({
  weeklyBillingId,
  text = 'Workerbee admin has disputed your weekly billing. Please, contact the support team for further questions',
}) => {

    const response = await ApiClient.disputeWeeklyBillingItem({
      id: weeklyBillingId,
      text,
    });
    return response;
  },
);

const fetchInvoicesList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  orderByField?: string,
  orderBySequence?: OrderingType,
  ids?: number[],
  status?: string | null,
  createAt?: string,
  purchaseOrderIds?: number[],
} & ActionSaveProps>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_FETCH_INVOICES_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    orderByField,
    orderBySequence= "ASC",
    ids,
    status,
    createAt,
    purchaseOrderIds,
  }) => {
    const response = await ApiClient.fetchInvoicesList({
      offset,
      limit,
      orderByField,
      orderBySequence,
      ids,
      status,
      createAt,
      purchaseOrderIds,
    });
    return response;
  },
);

const addInvoiceItem = createAsyncThunk<AxiosResponse<any>, InvoiceCreationProps>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_ADD_INVOICE_ITEM}`,
  async (invoice) => {
    const response = await ApiClient.addInvoiceItem(invoice);
    return response;
  },
);

const fetchInvoiceItem = createAsyncThunk<AxiosResponse<any>, number>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_FETCH_INVOICE_ITEM}`,
  async (id) => {
    const response = await ApiClient.fetchInvoiceItem(id);
    return response;
  },
);

const editInvoiceItem = createAsyncThunk<AxiosResponse<any>, InvoiceEditingProps>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_EDIT_INVOICE_ITEM}`,
  async (invoice) => {
    const response = await ApiClient.editInvoiceItem(invoice);
    return response;
  },
);

const sendViaStripeInvoiceItem = createAsyncThunk<AxiosResponse<any>, number>(
  `${REDUCER_KEY_ENTERPRISE_INVOICING}/${ACTION_NAME_SEND_VIA_STRIPE_INVOICE_ITEM}`,
  async (id) => {
    const response = await ApiClient.sendViaStripeInvoiceItem(id);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_ENTERPRISE_INVOICING,
  initialState,
  reducers: {
    resetEnterpriseInvoicing: (state) => {
      state.isFetching = false;
      state.weeklyBillings = [];
      state.countWeeklyBillings = 0;
      state.invoices = [];
      state.countInvoices = 0;
      state.page = 0;
      state.isInvoiceFetching = false;
    },
    resetWeeklyBillings: (state) => {
      state.isFetching = false;
      state.weeklyBillings = [];
      state.countWeeklyBillings = 0;
      state.page = 0;
    },
    changeWeeklyBillingTransferStatus: (state, {payload}) => {
      if (payload.weeklyBillingIds.length && state.weeklyBillings) {
        state.weeklyBillings = state.weeklyBillings.map((billing) => {
          if (payload.weeklyBillingIds.includes(billing.id)) {
            return {
              ...billing,
              transferRequests: billing.transferRequests.map((request) => ({
                ...request,
                status: payload.status
              }))
            }
          }
          return billing;
        })
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeeklyBillingsList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchWeeklyBillingsList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchWeeklyBillingsStat.fulfilled, (state, { payload }) => {
        state.weeklyBillingsStat = payload.data;
      })
      .addCase(fetchWeeklyBillingsList.fulfilled, (state, { payload }) => {
        state.weeklyBillings = payload.data.weeklyBillings;
        state.countWeeklyBillings = payload.data.count;
        state.page = payload.data.page;
        state.isFetching = false;
      })
      .addCase(fetchWeeklyBillingsFilteredList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchWeeklyBillingsFilteredList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchWeeklyBillingsFilteredList.fulfilled, (state, { payload }) => {
        state.weeklyBillings = payload.data.weeklyBillings;
        state.countWeeklyBillings = payload.data.count;
        state.page = payload.data.page;
        state.isFetching = false;
      })
      .addCase(fetchInvoicesList.pending, (state, {meta}) => {
        if (!meta.arg.withoutSave) {
          state.isFetching = true;
        }
      })
      .addCase(fetchInvoicesList.rejected, (state, {meta}) => {
        if (!meta.arg.withoutSave) {
          state.isFetching = false;
        }
      })
      .addCase(fetchInvoicesList.fulfilled, (state, { payload, meta }) => {
        if (!meta.arg.withoutSave) {
          state.invoices = payload.data.purchaseOrderInvoices;
          state.countInvoices = payload.data.count;
          state.page = payload.data.page;
          state.isFetching = false;
        }
      })
      .addCase(addInvoiceItem.pending, (state) => {
        state.isInvoiceFetching = true;
      })
      .addCase(addInvoiceItem.rejected, (state) => {
        state.isInvoiceFetching = false;
      })
      .addCase(addInvoiceItem.fulfilled, (state, { payload }) => {
        state.weeklyBillings = state.weeklyBillings.map((item) => {
          const exist = payload.data.summary.find((simIt: any) => simIt.wbId === item.id);
          if (!!exist) {
            return {...item, invoices: [...item.invoices, payload.data]};
          }
          return item;
        });
        state.isInvoiceFetching = false;
      })
      .addCase(fetchInvoiceItem.pending, (state) => {
        state.isInvoiceFetching = true;
      })
      .addCase(fetchInvoiceItem.rejected, (state) => {
        state.isInvoiceFetching = false;
      })
      .addCase(fetchInvoiceItem.fulfilled, (state, { payload }) => {
        state.isInvoiceFetching = false;
        state.currentInvoice = payload.data
      })
      .addCase(editInvoiceItem.pending, (state) => {
        state.isInvoiceFetching = true;
      })
      .addCase(editInvoiceItem.rejected, (state) => {
        state.isInvoiceFetching = false;
      })
      .addCase(editInvoiceItem.fulfilled, (state, { payload }) => {
        state.isInvoiceFetching = false;
        state.invoices = state.invoices.map(item => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.weeklyBillings = state.weeklyBillings.map((item) => {
          const exist = payload.data.summary.find((simIt: any) => simIt.wbId === item.id);
          if (!!exist) {
            return {...item, invoices: [...item.invoices, payload.data]};
          }
          return item;
        });
      })
      .addCase(sendViaStripeInvoiceItem.pending, (state) => {
        state.isInvoiceFetching = true;
      })
      .addCase(sendViaStripeInvoiceItem.rejected, (state) => {
        state.isInvoiceFetching = false;
      })
      .addCase(sendViaStripeInvoiceItem.fulfilled, (state, { payload }) => {
        state.isInvoiceFetching = false;
        state.invoices = state.invoices.map(item => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
      })
      .addCase(approveWeeklyBillingItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(approveWeeklyBillingItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(approveWeeklyBillingItem.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.weeklyBillings = state.weeklyBillings.map(item => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
      })
      .addCase(disputeWeeklyBillingItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(disputeWeeklyBillingItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(disputeWeeklyBillingItem.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.weeklyBillings = state.weeklyBillings.map(item => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
      });
  },
});

const {
  resetEnterpriseInvoicing,
  resetWeeklyBillings,
  changeWeeklyBillingTransferStatus,
} = actions;

export {
  resetEnterpriseInvoicing,
  resetWeeklyBillings,
  changeWeeklyBillingTransferStatus,
  fetchWeeklyBillingsList,
  fetchWeeklyBillingsFilteredList,
  approveWeeklyBillingItem,
  disputeWeeklyBillingItem,
  fetchInvoicesList,
  addInvoiceItem,
  fetchInvoiceItem,
  editInvoiceItem,
  sendViaStripeInvoiceItem,
  fetchWeeklyBillingsStat,
};

export default reducer;
