import { ROLE } from "models/const";

export enum ESeverity {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export enum EVerticalPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum EHorizontalPosition {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center",
}

export interface SnackbarProps {
  isOpen: boolean;
  severity?: ESeverity;
  vertical?: EVerticalPosition;
  horizontal?: EHorizontalPosition;
  message?: string;
};

export type OrderingType = "asc" | "desc" | "ASC" | "DESC";

export enum ERoleId {
  CUSTOMER_ADMIN = 4,
  ADMIN = 1,
  AGENCY_ADMIN = 6,
  CONSULTANT = 2,
  SELF_CUSTOMER = 3,
  CUSTOMER = 5,
  RESOURCE_MANAGER = 7,
  LEAD_MANAGER = 8,
  OPERATIONS = 9,
  SALES = 10,
  TALENT = 11,
}

export const adminRoles = [
  {
    name: ROLE.ADMIN,
    id: ERoleId.ADMIN,
    label: 'Admin'
  },
  {
    name: ROLE.OPERATIONS,
    id: ERoleId.OPERATIONS,
    label: 'Operations'
  },
  {
    name: ROLE.SALES,
    id: ERoleId.SALES,
    label: 'Sales'
  },
  {
    name: ROLE.TALENT,
    id: ERoleId.TALENT,
    label: 'Talent'
  }
]

export type ActionSaveProps = {
  withoutSave?: boolean;
}

export const availabilityWeek = [
  {
    value: '40',
    label: '40 h/week',
    availableOnCreation: true,
  },
  {
    value: '30',
    label: '30 h/week',
    availableOnCreation: true,
  },
  {
    value: '20',
    label: '20 h/week',
    availableOnCreation: true,
  },
  {
    value: '10',
    label: '10 h/week',
    availableOnCreation: true,
  },
  {
    value: '0',
    label: '0 h/week',
    availableOnCreation: false,
  },
  {
    value: 'null',
    label: 'null',
    availableOnCreation: false,
  },
]
