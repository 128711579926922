import axios, { AxiosResponse, AxiosError } from 'axios';
import { clearUser, setToken, setRefreshToken } from 'redux/slices/auth';

let isRefreshing = false;
let failedQueue: Array<any> = [];

const processQueue = (error: AxiosError | Error | null, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  failedQueue = [];
}


const initialConfig = {
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
};

const AxiosClient = axios.create({
  ...initialConfig,
  baseURL: process.env.REACT_APP_API_URL,
});

const AxiosClientNotAuth = axios.create({
  ...initialConfig,
  baseURL: process.env.REACT_APP_API_URL,
});

const handleRequest = (config: any, store: any) => {
  const { accessToken } = store.getState().auth;
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
};
const handleResponse = (response: AxiosResponse, store: any) => {
  return response;
};
const handleResponseError = (error: any, store: any) => {
  const { response } = error;
  const { refreshToken } = store.getState().auth;

  if (response) {
    if (response.status >= 500 && response.status < 600) {
      console.error({
        title: 'Internal server error',
        text: 'Server response with error, please try again later'
      });
    }

    if (response.status === 426) {
      if (refreshToken) {
        const originalRequest = error.config;

        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => AxiosClient(originalRequest))
            .catch(err => {
              return Promise.reject(err);
            });
        }

        isRefreshing = true;

        return new Promise((resolve, reject) => {
          axios.post(`${process.env.REACT_APP_API_URL}auth/new-tokens`, {}, {
            headers: {
              'Refresh-Auth': refreshToken
            }
          })
            .then(({ data }) => {
              store.dispatch(setToken({accessToken: data.accessToken}));
              store.dispatch(setRefreshToken({refreshToken: data.refreshToken}));
              processQueue(null, data.accessToken);
              resolve(AxiosClient(originalRequest));
            })
            .catch(err => {
              processQueue(err, null);
              console.error('Error on refresh');
              // clear persist data
              store.dispatch(clearUser());
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      } else {
        store.dispatch(clearUser());
      }
    }
  }

  return Promise.reject({
    ...error,
    message: response.data.message,
  });
};

export const setupResponseInterceptor = (store: any) => {
  AxiosClient.interceptors.request.use((config) => handleRequest(config, store));
  AxiosClient.interceptors.response.use(
    (response) => handleResponse(response, store),
    (error) => handleResponseError(error, store),
  );

  AxiosClientNotAuth.interceptors.request.use((config) => handleRequest(config, store));
  AxiosClientNotAuth.interceptors.response.use(
    (response) => handleResponse(response, store),
    (error) => handleResponseError(error, store),
  );
};

export {
  AxiosClient as default,
  AxiosClientNotAuth,
};
