import {createSlice, createAsyncThunk, isRejectedWithValue} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
  ACTION_NAME_ADD_LEAD_ITEM, ACTION_NAME_CREATE_PRE_ORDER_FROM_LEAD,
  ACTION_NAME_FETCH_LEAD_ITEM,
  ACTION_NAME_FETCH_LEAD_LIST, ACTION_NAME_FETCH_LEAD_SOURCE_AGENCY_LIST,
  REDUCER_KEY_LEADS,
} from '../constants';
import ApiClient from '../../api/ApiClient';
import { LeadCreationProps, LeadFilterProperty, LeadProps } from '../../types/leads';
import {PreOrderCreateProps, PreOrderProps} from "../../types/preOrder";
import {LeadSourceAgencyProps} from "../../types/leadSourceAgencies";

export interface LeadState {
  isFetching: boolean;
  leads: LeadProps[];
  count: number;
  page: number;
  selectedLead: LeadProps | undefined;
  isSaving: boolean;
  isCreatingPreOrder: boolean;
  preOrders: PreOrderProps[];
  leadSourceAgencies: LeadSourceAgencyProps[];
}

const initialState: LeadState = {
  isFetching: false,
  leads: [],
  count: 0,
  page: 0,
  selectedLead: undefined,
  isSaving: false,
  isCreatingPreOrder: false,
  preOrders: [],
  leadSourceAgencies: []
};

const fetchLeadList = createAsyncThunk<
  AxiosResponse<any>,
  { offset?: number; limit?: number; property?: LeadFilterProperty; type?: 'desc' | 'asc' }
>(
  `${REDUCER_KEY_LEADS}/${ACTION_NAME_FETCH_LEAD_LIST}`,
  async ({ offset = 0, limit = 25, type = 'asc', property }) => await ApiClient.fetchLeads(offset, limit),
);

const fetchLeadSourceAgencyList = createAsyncThunk<
    LeadSourceAgencyProps[],
    { leadSourceId: number; productId: number; workTypeId: number; }
>(
    `${REDUCER_KEY_LEADS}/${ACTION_NAME_FETCH_LEAD_SOURCE_AGENCY_LIST}`,
    async ({ leadSourceId, productId, workTypeId }) => {

      try {
        const response = await ApiClient.fetchLeadAgencies(leadSourceId, productId, workTypeId);
        return response.data.map((item: { agencyId: any; agency: { name: any; }; leadSourceId: any; productId: any; workActivities: string | any[]; }) => ({
          id: item.agencyId,
          name: item.agency.name,
          leadSourceId: item.leadSourceId,
          productId: item.productId,
          workTypeId: item.workActivities.length > 0
              ? item.workActivities[0].workTypeId
              : null
        }));
      } catch (error) {
        // @ts-ignore
        return isRejectedWithValue(error.response?.data || "An error occurred");
      }
    }
);

const fetchLeadItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_LEADS}/${ACTION_NAME_FETCH_LEAD_ITEM}`,
  async ({ id }) => {
    return await ApiClient.fetchLeadItem(id);
  },
);

const addLead = createAsyncThunk<AxiosResponse<any>, { lead: LeadCreationProps }>(
  `${REDUCER_KEY_LEADS}/${ACTION_NAME_ADD_LEAD_ITEM}`,
  async ({ lead }) => {
    const { ...values } = lead;
    const response = await ApiClient.addLeadItem({
      ...values,
    });
    return response;
  },
);

const createPreOrderFromLead = createAsyncThunk<AxiosResponse<any>, { preOrder: PreOrderCreateProps }>(
    `${REDUCER_KEY_LEADS}/${ACTION_NAME_CREATE_PRE_ORDER_FROM_LEAD}`,
    async ({ preOrder }) => {
      const { ...values } = preOrder;
      const response = await ApiClient.createPreOrderFromLead({
        ...values,
      });
      return response;
    },
);

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_LEADS,
  initialState,
  reducers: {
    resetLeads: (state) => {
      state.isFetching = false;
      state.leads = [];
      state.count = 0;
      state.page = 0;
      state.selectedLead = undefined;
      state.isSaving = false;
      state.isCreatingPreOrder = false;
      state.preOrders = [];
      state.leadSourceAgencies = []
    },
    resetLeadsSourceAgencies: (state) => {
      state.leadSourceAgencies = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeadList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchLeadList.fulfilled, (state, { payload }) => {
        state.leads = payload.data.leads;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(fetchLeadSourceAgencyList.fulfilled, (state, { payload }) => {
        state.leadSourceAgencies = payload;
      })
      .addCase(fetchLeadItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeadItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchLeadItem.fulfilled, (state, { payload }) => {
        state.selectedLead = payload.data;
        state.isFetching = false;
      })
      .addCase(addLead.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addLead.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addLead.fulfilled, (state, { payload }) => {
        state.leads.push(payload.data[0]);
        state.isSaving = false;
      })
      .addCase(createPreOrderFromLead.pending, (state) => {
        state.isCreatingPreOrder = true;
      })
      .addCase(createPreOrderFromLead.rejected, (state) => {
        state.isCreatingPreOrder = false;
      })
      .addCase(createPreOrderFromLead.fulfilled, (state, { payload }) => {
        const { leadId, id } = payload.data[0];

        const leadToUpdate = state.leads.find(lead => lead.id === leadId);
        if (leadToUpdate) {
          leadToUpdate.preOrderId = id;
        }

        state.preOrders.push(payload.data[0]);
        state.isCreatingPreOrder = false;
      });
  },
});

const { resetLeads, resetLeadsSourceAgencies } = actions;

export { resetLeads, resetLeadsSourceAgencies, fetchLeadList, fetchLeadSourceAgencyList, fetchLeadItem, addLead, createPreOrderFromLead };

export default reducer;
