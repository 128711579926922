import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import {
  ACTION_NAME_ADD_BUSINESS_RULE_ITEM,
  ACTION_NAME_BUSINESS_RULES_LIST,
  ACTION_NAME_UPDATE_BUSINESS_RULE_ITEM,
  ACTION_NAME_DELETE_BUSINESS_RULE_ITEM,
  REDUCER_KEY_BUSINESS_RULES,
  ACTION_NAME_UPDATE_BUSINESS_RULE_ACTION,
  ACTION_NAME_DELETE_BUSINESS_RULE_ACTION,
} from '../constants'
import ApiClient from '../../api/ApiClient';
import {
  BusinessRule,
  BusinessRuleAction,
  BusinessRuleCreation,
  BusinessRuleEdition,
} from '../../types/businessRules';

export interface BusinessRulesState {
  isFetching: boolean;
  businessRules: BusinessRule[];
  count: number;
  page: number;
  isSaving: boolean;
}

const initialState: BusinessRulesState = {
  isFetching: false,
  businessRules: [],
  count: 0,
  page: 0,
  isSaving: false,
}

const fetchBusinessRulesList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  appliesTo?: string,
  changeType?: string,
  search?: string,
}>(
  `${REDUCER_KEY_BUSINESS_RULES}/${ACTION_NAME_BUSINESS_RULES_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    appliesTo= "",
    changeType= "",
    search,
  }) => {
    const response = await ApiClient.fetchBusinessRulesList(offset, limit, appliesTo, changeType, search);
    return response;
  },
);

const addBusinessRule = createAsyncThunk<AxiosResponse<any>, { businessRule: BusinessRuleCreation }>(
  `${REDUCER_KEY_BUSINESS_RULES}/${ACTION_NAME_ADD_BUSINESS_RULE_ITEM}`,
  async ({
    businessRule,
  }) => {
    const { ...values } = businessRule;

    const response = await ApiClient.addBusinessRuleItem({
      ...values,
    });
    return response;
  },
);

const updateBusinessRule = createAsyncThunk<AxiosResponse<any>, { businessRule: BusinessRuleEdition }>(
  `${REDUCER_KEY_BUSINESS_RULES}/${ACTION_NAME_UPDATE_BUSINESS_RULE_ITEM}`,
  async ({
    businessRule,
  }) => {
    const response = await ApiClient.updateBusinessRuleItem(businessRule);
    return response;
  },
);

const updateBusinessRuleAction = createAsyncThunk<AxiosResponse<any>, { businessRule: BusinessRuleEdition, businessRuleAction: BusinessRuleAction }>(
  `${REDUCER_KEY_BUSINESS_RULES}/${ACTION_NAME_UPDATE_BUSINESS_RULE_ACTION}`,
  async ({
    businessRule,
    businessRuleAction,
  }) => {
    const response = await ApiClient.updateBusinessRuleAction(businessRule, businessRuleAction);
    return response;
  },
);

const deleteBusinessRule = createAsyncThunk<AxiosResponse<any>, { businessRule: BusinessRuleEdition }>(
  `${REDUCER_KEY_BUSINESS_RULES}/${ACTION_NAME_DELETE_BUSINESS_RULE_ITEM}`,
  async ({
    businessRule,
  }) => {
    const response = await ApiClient.deleteBusinessRuleItem(businessRule);
    return response;
  },
);

const deleteBusinessRuleAction = createAsyncThunk<AxiosResponse<any>, { businessRule: BusinessRuleEdition, businessRuleAction: BusinessRuleAction }>(
  `${REDUCER_KEY_BUSINESS_RULES}/${ACTION_NAME_DELETE_BUSINESS_RULE_ACTION}`,
  async ({
    businessRule,
    businessRuleAction,
  }) => {
    const response = await ApiClient.deleteBusinessRuleAction(businessRule, businessRuleAction);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_BUSINESS_RULES,
  initialState,
  reducers: {
    resetBusinessRules: (state) => {
      state.isFetching = false;
      state.businessRules = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessRulesList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBusinessRulesList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchBusinessRulesList.fulfilled, (state, { payload }) => {
        state.businessRules = payload.data.businessRules;
        state.count = payload.data.count;
        state.page = payload.data.page;
        state.isFetching = false;
      })
      .addCase(addBusinessRule.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addBusinessRule.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addBusinessRule.fulfilled, (state, { payload }) => {
        state.businessRules.push(payload.data);
        state.isSaving = false;
      })
      .addCase(updateBusinessRule.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateBusinessRule.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateBusinessRule.fulfilled, (state, { payload }) => {
        state.businessRules = state.businessRules.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isSaving = false;
      })
      .addCase(updateBusinessRuleAction.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateBusinessRuleAction.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateBusinessRuleAction.fulfilled, (state, { payload }) => {         
        state.businessRules = state.businessRules.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isSaving = false;
      })
      .addCase(deleteBusinessRule.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(deleteBusinessRule.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(deleteBusinessRule.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })
      .addCase(deleteBusinessRuleAction.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(deleteBusinessRuleAction.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(deleteBusinessRuleAction.fulfilled, (state, { payload }) => {         
        state.isSaving = false;
      });

  },
});

const {
  resetBusinessRules,
} = actions;

export {
  resetBusinessRules,
  fetchBusinessRulesList,
  addBusinessRule,
  updateBusinessRule,
  updateBusinessRuleAction,
  deleteBusinessRule,
  deleteBusinessRuleAction,
};

export default reducer;
