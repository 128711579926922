import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ROUTES } from '../../constants';
import { useAppDispatch } from "redux/hooks";
import { setSnackNotification } from "redux/slices/snackNotifications";
import { ESeverity } from "types/common";
import { recoverPassword } from "redux/slices/auth";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const RecoverSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .max(255, "Password must be at most 255 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
})

function RecoverPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {mailToken} = location.state as {mailToken: string};

  const handleSubmit =  (values: any) => {
    if (mailToken) {
      dispatch(recoverPassword({
        password: values.password,
        token: mailToken
      }))
      .unwrap()
      .then(() => {
        dispatch(setSnackNotification({
          isOpen: true,
          severity: ESeverity.SUCCESS,
          message: 'Password succesfully changed'
        }));
        navigate(ROUTES.SIGN_IN);
      })
      .catch((err) => {
        dispatch(setSnackNotification({
          isOpen: true,
          severity: ESeverity.ERROR,
          message: err.message
        }));
      })
    }
  }

  return (
    <>
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={RecoverSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        dirty,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty}
          >
            Reset password
          </Button>
        </form>
      )}
    </Formik>
    <Box mt={3} display="flex" justifyContent="center">
      <Link to={`${ROUTES.SIGN_IN}`}>Sign in</Link>
    </Box>
    </>
  );
}

export default RecoverPassword;
