export const DEFAULT_VALUES = { status: 'created' };

export enum ETransferRequestStatus {
  CREATED = "created",
  TRANSFER_TASK_CREATED = "task_created",
  AGENCY_DELAYED_TASK = "agency_delayed_task",
  AGENCY_PROCESSING_TASK = "agency_processing_task",
  TRANSFERRED = "transferred",
  CANCELLED = "cancelled",
}

export const TransferStatus: {
  [key in ETransferRequestStatus]: {
    title: string;
    color: 'success' | 'warning' | 'error' | 'primary' | 'secondary' | 'info' | string;
  }
} = {
  [ETransferRequestStatus.CREATED]: {
    title: 'Created',
    color: 'warning'
  },
  [ETransferRequestStatus.TRANSFER_TASK_CREATED]: {
    title: 'Processing',
    color: '#5f5f5f'
  },
  [ETransferRequestStatus.AGENCY_DELAYED_TASK]: {
    title: 'To Payout',
    color: 'success',
  },
  [ETransferRequestStatus.AGENCY_PROCESSING_TASK]: {
    title: 'In Payout',
    color: '#818181'
  },
  [ETransferRequestStatus.TRANSFERRED]: {
    title: 'Transferred',
    color: 'primary',
  },
  [ETransferRequestStatus.CANCELLED]: {
    title: 'Cancelled',
    color: 'error',
  },
}