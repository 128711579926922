import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_CHANGE_LOGS,
  ACTION_NAME_FETCH_ORDER_ITEM_LOGS,
} from '../constants'
import ApiClient from '../../api/ApiClient';

export interface ChangeLogState {
  isFetching: boolean;
  changes: any[];
  count: number;
  page: number;
}

const initialState: ChangeLogState = {
  isFetching: false,
  changes: [],
  count: 0,
  page: 0,
}

const fetchOrderItemLogs = createAsyncThunk<AxiosResponse<any>, { id: number, offset?: number, limit?: number }>(
  `${REDUCER_KEY_CHANGE_LOGS}/${ACTION_NAME_FETCH_ORDER_ITEM_LOGS}`,
  async ({
    id,
    offset = 0,
    limit = 25,
  }) => {
    const response = await ApiClient.fetchOrderItemLogs(id, offset, limit);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_CHANGE_LOGS,
  initialState,
  reducers: {
    resetLogs: (state) => {
      state.isFetching = false;
      state.changes = [];
      state.page = 0;
      state.count = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderItemLogs.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrderItemLogs.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchOrderItemLogs.fulfilled, (state, { payload }) => {
        state.changes = payload.data.changes;
        state.page = payload.data.page;
        state.count = payload.data.count;
        state.isFetching = false;
      })
  },
});

const {
  resetLogs,
} = actions;

export {
  resetLogs,
  fetchOrderItemLogs,
};

export default reducer;
