import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_AGENCIES,
  ACTION_NAME_ADD_AGENCY_ITEM,
  ACTION_NAME_EDIT_AGENCY_ITEM,
  ACTION_NAME_FETCH_AGENCIES_LIST,
  ACTION_NAME_ADD_AGENCY_ITEM_LOGO, ACTION_NAME_CHANGE_AGENCY_TEAM_ACTIVE_STATUS,
  ACTION_NAME_FETCH_AGENCY_ITEM,
} from '../constants'
import ApiClient from '../../api/ApiClient';
import dataToImgFile from '../../utils/dataToImgFile';
import {
  AgencyCreationProps,
  AgencyEnterpriseEditingProps,
  AgencyProps,
} from '../../types/agencies';

export interface AgenciesState {
  isFetching: boolean;
  agencies: AgencyProps[];
  count: number;
  page: number;
  isSaving: boolean;
}

const initialState: AgenciesState = {
  isFetching: false,
  agencies: [],
  count: 0,
  page: 0,
  isSaving: false,
}

const fetchAgenciesList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  search?: string,
  isEnterprise?: boolean,
  property?: string,
  type?: "desc" | "asc",
  isActive?: boolean,
}>(
  `${REDUCER_KEY_AGENCIES}/${ACTION_NAME_FETCH_AGENCIES_LIST}`,
  async ({
  offset = 0,
  limit = 25,
  search,
  isEnterprise,
  property,
  type,
  isActive,
}) => {
    const response = await ApiClient.fetchAgenciesList({ offset, limit, search, isEnterprise, property, type, isActive });
    return response;
  },
);

const fetchAgencyItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_AGENCIES}/${ACTION_NAME_FETCH_AGENCY_ITEM}`,
  async ({ id }) => {
    const response = await ApiClient.fetchAgencyItem(id);
    return response;
  },
);

const addAgencyItem = createAsyncThunk<AxiosResponse<any>, { agency: AgencyCreationProps }>(
  `${REDUCER_KEY_AGENCIES}/${ACTION_NAME_ADD_AGENCY_ITEM}`,
  async ({ agency }, { dispatch }) => {
    const response = await ApiClient.addAgencyItem(agency);
    return response;
  },
);

const editAgencyItem = createAsyncThunk<AxiosResponse<any>, { agency: AgencyEnterpriseEditingProps }>(
  `${REDUCER_KEY_AGENCIES}/${ACTION_NAME_EDIT_AGENCY_ITEM}`,
  async ({ agency }, { dispatch }) => {
    const response = await ApiClient.editAgencyItem(agency);
    return response;
  },
);

const addAgencyItemLogo = createAsyncThunk<AxiosResponse<any>, { agencyId: number, logo: string }>(
  `${REDUCER_KEY_AGENCIES}/${ACTION_NAME_ADD_AGENCY_ITEM_LOGO}`,
  async ({
    agencyId,
    logo,
  }, { dispatch }) => {
    const { blob: logo_image } = dataToImgFile(logo);
    const logoData = new FormData();
    logoData.append('logo_image', logo_image as Blob, 'logo.jpg');
    const response = await ApiClient.addAgencyItemLogo(agencyId, logoData);
    return {...response, logo};
  },
);

const changeAgencyTeamActiveStatus = createAsyncThunk<AxiosResponse<any>, { agencyId: number }>(
  `${REDUCER_KEY_AGENCIES}/${ACTION_NAME_CHANGE_AGENCY_TEAM_ACTIVE_STATUS}`,
  async ({ agencyId }, { dispatch }) => {
    const response = await ApiClient.changeAgencyTeamActiveStatus(agencyId);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_AGENCIES,
  initialState,
  reducers: {
    resetAgencies: (state) => {
      state.isFetching = false;
      state.agencies = [];
    },
    updateAgencyItem: (state, { payload }: PayloadAction<{ agency: AgencyProps }>) => {
      state.agencies.forEach((agencyItem) => {
        if (agencyItem.id === payload.agency.id) {
          if (payload.agency.name) {
            agencyItem.name = payload.agency.name;
          }
          if (payload.agency.description) {
            agencyItem.description = payload.agency.description;
          }
          if (payload.agency.logo) {
            agencyItem.logo = payload.agency.logo;
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgenciesList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAgenciesList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchAgenciesList.fulfilled, (state, { payload }) => {
        state.agencies = payload.data.companies;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(addAgencyItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addAgencyItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addAgencyItem.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })
      .addCase(editAgencyItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(editAgencyItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(editAgencyItem.fulfilled, (state, { payload }) => {
        state.agencies = state.agencies.map((item) => {
          if (item.id === payload.data.id) {
            return {
              ...item,
              ...payload.data,
            };
          }
          return item;
        });
        state.isSaving = false;
      })
      .addCase(addAgencyItemLogo.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addAgencyItemLogo.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addAgencyItemLogo.fulfilled, (state, { payload }) => {
        state.agencies = state.agencies.map((item) => {
          if (item.id === payload.data.id) {
            // @ts-ignore
            return { ...item, logo: payload.logo };
          }
          return item;
        });
        state.isSaving = false;
      })

      .addCase(changeAgencyTeamActiveStatus.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(changeAgencyTeamActiveStatus.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(changeAgencyTeamActiveStatus.fulfilled, (state, { payload }) => {
        state.agencies = state.agencies.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isSaving = false;
      });
  },
});

const {
  resetAgencies,
  updateAgencyItem,
} = actions;

export {
  resetAgencies,
  updateAgencyItem,
  fetchAgenciesList,
  addAgencyItem,
  editAgencyItem,
  addAgencyItemLogo,
  changeAgencyTeamActiveStatus,
  fetchAgencyItem,
};

export default reducer;
