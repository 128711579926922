import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_TAGS,
  ACTION_NAME_FETCH_TAGS_LIST,
  ACTION_NAME_ADD_TAG_ITEM,
  ACTION_NAME_EDIT_TAG_ITEM,
} from '../constants';
import ApiClient from '../../api/ApiClient';
import { TagProps, TagsCreationProps } from '../../types/tag';

export interface TagsState {
  isFetching: boolean;
  tags: TagProps[];
  count: number;
  page: number;
  isSaving: boolean;
}

const initialState: TagsState = {
  isFetching: false,
  tags: [],
  count: 0,
  page: 0,
  isSaving: false,
};

const fetchTagsList = createAsyncThunk<AxiosResponse<any>, { offset?: number; limit?: number }>(
  `${REDUCER_KEY_TAGS}/${ACTION_NAME_FETCH_TAGS_LIST}`,
  async ({ offset = 0, limit = 25 }) => {
    const response = await ApiClient.fetchTags(offset, limit);
    return response;
  },
);

const addTagItem = createAsyncThunk<AxiosResponse<any>, { tag: TagsCreationProps }>(
  `${REDUCER_KEY_TAGS}/${ACTION_NAME_ADD_TAG_ITEM}`,
  async ({ tag }) => {
    const response = await ApiClient.addTagItem(tag);
    return response;
  },
);

const editTagItem = createAsyncThunk<AxiosResponse<any>, { tag: TagProps }>(
  `${REDUCER_KEY_TAGS}/${ACTION_NAME_EDIT_TAG_ITEM}`,
  async ({ tag }, { dispatch }) => {
    const response = await ApiClient.editTagItem(tag);
    if (response.status === 200) {
      dispatch(updateTagItem({ tag }));
    }
    return response;
  },
);

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_TAGS,
  initialState,
  reducers: {
    resetTags: (state) => {
      state.isFetching = false;
      state.tags = [];
    },
    updateTagItem: (state, { payload }: PayloadAction<{ tag: TagProps }>) => {
      state.tags.forEach((tagItem) => {
        if (tagItem.id === payload.tag.id) {
          tagItem.name = payload.tag.name;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagsList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchTagsList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchTagsList.fulfilled, (state, { payload }) => {
        state.tags = payload.data.tags;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(addTagItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addTagItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addTagItem.fulfilled, (state, { payload }) => {
        // payload.data is array of tags
        state.isSaving = false;
        state.tags.push(...payload.data);
        state.count++;
      })
      .addCase(editTagItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(editTagItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(editTagItem.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      });
  },
});

const { resetTags, updateTagItem } = actions;

export { resetTags, fetchTagsList, addTagItem, editTagItem };

export default reducer;
