import * as React from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from '../../redux/hooks';
import { authenticationSelector, initializingSelector } from '../../redux/selectors/getAuthData';
import { ROUTES } from '../../constants';

interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const isAuthenticated = useAppSelector(authenticationSelector);
  const isInitialized = useAppSelector(initializingSelector);

  if (isInitialized && isAuthenticated) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
