import React from 'react';
import {Typography} from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";
import { Wrapper } from 'components/auth/styled';

const SignIn = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
