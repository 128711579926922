import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ACTION_NAME_DEACTIVATE_USER,
  ACTION_NAME_INVITE_USER,
  ACTION_NAME_REMOVE_USER,
  ACTION_NAME_RESEND_INVITE,
  ACTION_NAME_USERS_LIST,
  REDUCER_KEY_USERS,
} from '../constants';
import ApiClient from '../../api/ApiClient';
import { User, UserFilter, UserInvite } from 'types/user';

export interface UsersState {
  isFetching: boolean;
  users: User[];
  count: number;
  page: number;
  isSaving: boolean;
}

const initialState: UsersState = {
  isFetching: false,
  users: [],
  count: 0,
  page: 0,
  isSaving: false,
};

const fetchUsersList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number;
  limit?: number;
  property?: string;
  type?: "desc" | "asc";
  filter?: UserFilter
}>(
  `${REDUCER_KEY_USERS}/${ACTION_NAME_USERS_LIST}`,
  async ({ offset = 0, limit = 25, property, type, filter }) => {
    const response = await ApiClient.fetchUserList(offset, limit, property, type, filter);
    return response;
  },
);

const addUser = createAsyncThunk<User[], UserInvite>(
  `${REDUCER_KEY_USERS}/${ACTION_NAME_INVITE_USER}`,
  async ( userInvite ) => {
    const response = await ApiClient.addUser(userInvite);
    return response.data;
  },
);

const deactivateUser = createAsyncThunk<AxiosResponse<any>, number[]>(
  `${REDUCER_KEY_USERS}/${ACTION_NAME_DEACTIVATE_USER}`,
  async ( ids ) => {
    const response = await ApiClient.deactivateUser(ids);
    return response;
  },
)

const resendUserInvite = createAsyncThunk<AxiosResponse<any>, UserInvite>(
  `${REDUCER_KEY_USERS}/${ACTION_NAME_RESEND_INVITE}`,
  async ( userInvite ) => {
    const response = await ApiClient.resendUserInvite(userInvite);
    return response;
  },
)

const removeUser = createAsyncThunk<AxiosResponse<any>, number[]>(
  `${REDUCER_KEY_USERS}/${ACTION_NAME_REMOVE_USER}`,
  async ( ids ) => {
    const response = await ApiClient.removeUser(ids);
    return response;
  },
)

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_USERS,
  initialState,
  reducers: {
    resetUsers: (state) => {
      state.isFetching = false;
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchUsersList.fulfilled, (state, { payload }) => {
        state.users = payload.data.users;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(addUser.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addUser.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.users = [...state.users, ...payload.map((item) => ({...item}))];
        state.count = state.count++;
      })
      .addCase(deactivateUser.fulfilled, (state, { payload, meta }) => {
        if (payload.data.length && payload.data[0].success) {
          state.users = state.users.map((item) => {
            if (meta.arg.includes(item.id)) {
              return {
                ...item,
                isActive: !item.isActive
              }
            }
            return item;
          })
        }
      })
      .addCase(removeUser.fulfilled, (state, {payload, meta }) => {
        if (payload.data.length && payload.data[0].success) {
          state.users = state.users.filter((item) => !meta.arg.includes(item.id));
        }
      });
  },
});

const { resetUsers } = actions;

export { resetUsers, fetchUsersList, addUser, deactivateUser, resendUserInvite, removeUser };

export default reducer;
