import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_ORDERS,
  ACTION_NAME_FETCH_ORDERS_LIST,
  ACTION_NAME_FETCH_ORDER_ITEM,
  ACTION_NAME_DELETE_ORDER_ITEM,
  ACTION_NAME_CHANGE_STATUS_ORDER_ITEM,
  ACTION_NAME_EDITE_ORDER_ITEM, ACTION_NAME_EDITE_ORDER_ITEM_END_DATE,
  ACTION_NAME_CREATE_ORDER,
} from '../constants'
import ApiClient from '../../api/ApiClient';
import {OrderCreateDto, OrderEditingProps, OrderFilterQueryProps, OrderProps} from '../../types/order';

export interface OrdersState {
  isFetching: boolean;
  orders: OrderProps[];
  count: number;
  page: number;
  selectedOrderItem: OrderProps | undefined;
}

const initialState: OrdersState = {
  isFetching: false,
  orders: [],
  count: 0,
  page: 0,
  selectedOrderItem: undefined,
}

const fetchOrdersList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  search?: string,
  property?: string,
  type?: "desc" | "asc",
  filter?: OrderFilterQueryProps,
}>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_FETCH_ORDERS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    property,
    type= "asc",
    search,
    filter,
  }) => {
    const response = await ApiClient.fetchOrders({ offset, limit, property, type, search, filter });
    return response;
  },
);

const fetchOrderItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_FETCH_ORDER_ITEM}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchOrderItem(id);
    return response;
  },
);

const changeStatusOrderItem = createAsyncThunk<AxiosResponse<any>, { id: number, status: string }>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_CHANGE_STATUS_ORDER_ITEM}`,
  async ({
    id,
    status,
  }, { dispatch }) => {
    const response = await ApiClient.changeStatusOrder(id, status);
    await dispatch(fetchOrderItem({id}));
    return response;
  },
);

const createOrder = createAsyncThunk<AxiosResponse<any>, OrderCreateDto>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_CREATE_ORDER}`,
  async (orderForm) => {
    const response = await ApiClient.createOrder(orderForm);
    return response;
  }
)

const editOrderItem = createAsyncThunk<AxiosResponse<any>, { id: number, order: OrderEditingProps }>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_EDITE_ORDER_ITEM}`,
  async ({ id, order }) => {
    const response = await ApiClient.editOrder(id, order);
    return response;
  },
);

const editOrderItemEndDate = createAsyncThunk<AxiosResponse<any>, {
  id: number,
  endDate: string,
  availability: number,
}>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_EDITE_ORDER_ITEM_END_DATE}`,
  async ({ id, endDate, availability }) => {
    const response = await ApiClient.editOrderItemEndDate({ id, endDate, availability });
    return response;
  },
);

const deleteOrderItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_ORDERS}/${ACTION_NAME_DELETE_ORDER_ITEM}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.deleteOrder(id);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_ORDERS,
  initialState,
  reducers: {
    resetOrders: (state) => {
      state.isFetching = false;
      state.orders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrdersList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchOrdersList.fulfilled, (state, { payload }) => {
        state.orders = payload.data.orders;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(fetchOrderItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrderItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchOrderItem.fulfilled, (state, { payload }) => {
        state.selectedOrderItem = payload.data;
        state.isFetching = false;
      })
      .addCase(changeStatusOrderItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeStatusOrderItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(changeStatusOrderItem.fulfilled, (state, { payload }) => {
        state.isFetching = false;
      })

      .addCase(editOrderItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editOrderItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(editOrderItem.fulfilled, (state,  { payload }) => {
        state.selectedOrderItem = {
          ...payload.data[0]
        };
        state.isFetching = false;
      })

      .addCase(editOrderItemEndDate.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editOrderItemEndDate.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(editOrderItemEndDate.fulfilled, (state,  { payload }) => {
        state.selectedOrderItem = {
          ...payload.data
        };
        state.isFetching = false;
      })

      .addCase(deleteOrderItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteOrderItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(deleteOrderItem.fulfilled, (state) => {
        state.isFetching = false;
      });
  },
});

const {
  resetOrders,
} = actions;

export {
  resetOrders,
  fetchOrdersList,
  fetchOrderItem,
  changeStatusOrderItem,
  editOrderItem,
  editOrderItemEndDate,
  deleteOrderItem,
  createOrder,
};

export default reducer;
