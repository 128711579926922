import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_CONSULTANTS,
  ACTION_NAME_FETCH_CONSULTANTS_LIST,
  ACTION_NAME_APPROVE_CONSULTANT,
  ACTION_NAME_DECLINE_CONSULTANT,
  ACTION_NAME_FETCH_CONSULTANT_ITEM,
  ACTION_NAME_MARK_INTERNAL_CONSULTANT,
  ACTION_NAME_FETCH_CONSULTANT_ITEM_PRODUCTS,
  ACTION_NAME_FETCH_CONSULTANT_ITEM_LANGUAGES,
  ACTION_NAME_CHANGE_CONSULTANT_ACTIVE_STATUS,
  ACTION_NAME_CHANGE_CONSULTANT_CALL_SCHEDULED_STATUS,
  ACTION_NAME_CHANGE_CONSULTANT_EMAIL,
  ACTION_NAME_UPDATE_CONSULTANT_ITEM,
  ACTION_NAME_DOWNLOAD_CV,
  ACTION_NAME_FETCH_CONSULTANTS_SUMMARY_DATA,
  ACTION_NAME_FETCH_APPROVED_CONSULTANTS_SUMMARY_DATA, ACTION_NAME_FETCH_CONSULTANT_HASH_CODE,
} from '../constants'
import ApiClient from 'api/ApiClient';
import { ConsultantLanguageItemProps } from 'types/language';
import {
  ApprovedConsultantSummaryDataItem,
  ConsultantFilterQueryProps,
  ConsultantSummaryDataProps,
} from 'types/consultant';

export interface ConsultantsState {
  isFetching: boolean;
  consultants: any[];
  count: number;
  page: number;
  selectedConsultantItem: any | undefined;
  selectedConsultantItemProducts: any[];
  selectedConsultantItemLanguages: ConsultantLanguageItemProps[];
  summaryData?: ConsultantSummaryDataProps;
  approvedConsultantsSummary?: ApprovedConsultantSummaryDataItem[];
  isFetchingAdditional: boolean;
}

const initialState: ConsultantsState = {
  isFetching: false,
  consultants: [],
  count: 0,
  page: 0,
  selectedConsultantItem: undefined,
  selectedConsultantItemProducts: [],
  selectedConsultantItemLanguages: [],
  summaryData: undefined,
  approvedConsultantsSummary: undefined,
  isFetchingAdditional: false,
}

const fetchConsultantsList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  property?: string,
  type?: "desc" | "asc",
  search?: string,
  filter?: ConsultantFilterQueryProps,
}>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANTS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    property,
    type= "asc",
    search,
    filter,
  }) => {
    const response = await ApiClient.fetchConsultants({ offset, limit, property, type, search, filter });
    return response;
  },
);

const fetchConsultantItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANT_ITEM}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchConsultantItem(id);
    return response;
  },
);

const updateConsultantItem = createAsyncThunk<AxiosResponse<any>, { id: number, params: any }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_UPDATE_CONSULTANT_ITEM}`,
  async ({
    id,
    params
  }) => {
    const response = await ApiClient.updateConsultantItem(id, params);
    return response;
  },
);

const fetchUserCV = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_DOWNLOAD_CV}`,
  async ({
    id
  }) => {
    const response = await ApiClient.fetchCv(id);
    return response;
  },
);

const approveConsultant = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_APPROVE_CONSULTANT}`,
  async ({
    id,
  }, { dispatch }) => {
    const response = await ApiClient.approveConsultant(id);
    await dispatch(fetchConsultantItem({id}));
    return response;
  },
);

const declineConsultant = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_DECLINE_CONSULTANT}`,
  async ({
    id,
  }, { dispatch }) => {
    const response = await ApiClient.declineConsultant(id);
    await dispatch(fetchConsultantItem({id}));
    return response;
  },
);

const markInternalConsultant = createAsyncThunk<AxiosResponse<any>, { id: number, isInternalConsultant: boolean }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_MARK_INTERNAL_CONSULTANT}`,
  async ({
    id,
    isInternalConsultant,
  }, { dispatch }) => {
    const response = await ApiClient.markInternalConsultant({ id, isInternalConsultant });
    return { isInternalConsultant, ...response };
  },
);

const fetchConsultantItemProducts = createAsyncThunk<AxiosResponse<any>, { id: number, offset?: number, limit?: number, }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANT_ITEM_PRODUCTS}`,
  async ({
    id,
    offset = 0,
    limit = 100,
  }) => {
    const response = await ApiClient.fetchConsultantItemProducts(id, offset, limit);
    return response;
  },
);

const fetchConsultantItemLanguages = createAsyncThunk<AxiosResponse<any>, { id: number, offset?: number, limit?: number, }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANT_ITEM_LANGUAGES}`,
  async ({
    id,
    offset = 0,
    limit = 100,
  }) => {
    const response = await ApiClient.fetchConsultantItemLanguages(id, offset, limit);
    return response;
  },
);

const changeConsultantActiveStatus = createAsyncThunk<AxiosResponse<any>, { userId: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_CHANGE_CONSULTANT_ACTIVE_STATUS}`,
  async ({ userId }, { dispatch }) => {
    const response = await ApiClient.changeConsultantActiveStatus(userId);
    return response;
  },
);

const changeConsultantCallScheduledStatus = createAsyncThunk<AxiosResponse<any>, { userId: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_CHANGE_CONSULTANT_CALL_SCHEDULED_STATUS}`,
  async ({ userId }, { dispatch }) => {
    const response = await ApiClient.changeConsultantCallScheduledStatus(userId);
    await dispatch(fetchConsultantItem({id: userId}));
    return response;
  },
)

const changeConsultantEmail = createAsyncThunk<AxiosResponse<any>, { userId: number, email: string }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_CHANGE_CONSULTANT_EMAIL}`,
  async ({ userId, email }) => {
    const response = await ApiClient.changeConsultantEmail({ id: userId, email });
    return response;
  },
);

const fetchConsultantsSummaryData = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANTS_SUMMARY_DATA}`,
  async () => {
    const response = await ApiClient.fetchConsultantsSummaryData();
    return response;
  },
);

const fetchApprovedConsultantsSummaryData = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_APPROVED_CONSULTANTS_SUMMARY_DATA}`,
  async () => {
    const response = await ApiClient.fetchApprovedConsultantsSummaryData();
    return response;
  },
);

const fetchConsultantHashCode = createAsyncThunk<AxiosResponse<any>, { userId: number }>(
  `${REDUCER_KEY_CONSULTANTS}/${ACTION_NAME_FETCH_CONSULTANT_HASH_CODE}`,
  async ({ userId }) => {
    const response = await ApiClient.fetchConsultantHashCode(userId);
    return response;
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_CONSULTANTS,
  initialState,
  reducers: {
    resetConsultants: (state) => {
      state.isFetching = false;
      state.consultants = [];
      state.summaryData = undefined;
      state.approvedConsultantsSummary = undefined;
    },
    resetSelectedConsultant: (state) => {
      state.isFetching = false;
      state.selectedConsultantItem = undefined;
      state.selectedConsultantItemProducts = [];
      state.selectedConsultantItemLanguages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultantsList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchConsultantsList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchConsultantsList.fulfilled, (state, { payload }) => {
        state.consultants = payload.data.consultants;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(fetchConsultantItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchConsultantItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchConsultantItem.fulfilled, (state, { payload }) => {
        state.selectedConsultantItem = payload.data;
        state.isFetching = false;
      })
      .addCase(updateConsultantItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateConsultantItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateConsultantItem.fulfilled, (state, { payload }) => {
        state.selectedConsultantItem = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchUserCV.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserCV.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchUserCV.fulfilled, (state, { payload }) => {
        state.isFetching = false;
      })
      .addCase(approveConsultant.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(approveConsultant.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(approveConsultant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
      })
      .addCase(declineConsultant.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(declineConsultant.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(declineConsultant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
      })
      .addCase(markInternalConsultant.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(markInternalConsultant.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(markInternalConsultant.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        if (payload.data.success) {
          // @ts-ignore
          if (payload.isInternalConsultant) {
            state.selectedConsultantItem = {
              ...state.selectedConsultantItem,
              consultant: {
                ...state.selectedConsultantItem.consultant,
                becameInternalAt: new Date().toString(),
              },
            }
          } else {
            state.selectedConsultantItem = {
              ...state.selectedConsultantItem,
              consultant: {
                ...state.selectedConsultantItem.consultant,
                becameInternalAt: null,
              },
            }
          }
        }
      })
      .addCase(fetchConsultantItemProducts.pending, (state) => {
        state.isFetching = true;
        state.selectedConsultantItemProducts = [];
      })
      .addCase(fetchConsultantItemProducts.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchConsultantItemProducts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.selectedConsultantItemProducts = payload.data;
      })
      .addCase(fetchConsultantItemLanguages.pending, (state) => {
        state.isFetching = true;
        state.selectedConsultantItemProducts = [];
      })
      .addCase(fetchConsultantItemLanguages.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchConsultantItemLanguages.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.selectedConsultantItemLanguages = payload.data;
      })

      .addCase(changeConsultantActiveStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeConsultantActiveStatus.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(changeConsultantActiveStatus.fulfilled, (state, { payload }) => {
        state.selectedConsultantItem = payload.data;
        state.isFetching = false;
      })
      .addCase(changeConsultantCallScheduledStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeConsultantCallScheduledStatus.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(changeConsultantCallScheduledStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
      })

      .addCase(changeConsultantEmail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(changeConsultantEmail.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(changeConsultantEmail.fulfilled, (state, { payload }) => {
        state.selectedConsultantItem = payload.data;
        state.isFetching = false;
      })

      .addCase(fetchConsultantsSummaryData.pending, (state) => {
        state.isFetching = true;
        state.summaryData = undefined;
      })
      .addCase(fetchConsultantsSummaryData.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchConsultantsSummaryData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.summaryData = payload.data;
      })

      .addCase(fetchApprovedConsultantsSummaryData.pending, (state) => {
        state.isFetching = true;
        state.approvedConsultantsSummary = undefined;
      })
      .addCase(fetchApprovedConsultantsSummaryData.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchApprovedConsultantsSummaryData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.approvedConsultantsSummary = payload.data;
      })

      .addCase(fetchConsultantHashCode.pending, (state) => {
        state.isFetchingAdditional = true;
      })
      .addCase(fetchConsultantHashCode.rejected, (state) => {
        state.isFetchingAdditional = false;
      })
      .addCase(fetchConsultantHashCode.fulfilled, (state, { payload }) => {
        state.isFetchingAdditional = false;
        if (payload.data.id === state?.selectedConsultantItem?.id) {
          state.selectedConsultantItem = {
            ...state.selectedConsultantItem,
            consultant: {
              ...state.selectedConsultantItem.consultant,
              uidHashCode: payload.data.uidHashCode,
            },
          };
        }
      });
  },
});

const {
  resetConsultants,
  resetSelectedConsultant,
} = actions;

export {
  resetConsultants,
  resetSelectedConsultant,
  fetchConsultantsList,
  fetchConsultantItem,
  updateConsultantItem,
  approveConsultant,
  declineConsultant,
  markInternalConsultant,
  fetchConsultantItemProducts,
  fetchConsultantItemLanguages,
  changeConsultantActiveStatus,
  changeConsultantCallScheduledStatus,
  changeConsultantEmail,
  fetchUserCV,
  fetchConsultantsSummaryData,
  fetchApprovedConsultantsSummaryData,
  fetchConsultantHashCode,
};

export default reducer;
