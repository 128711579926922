import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ACTION_NAME_FETCH_LEAD_SOURCE_LIST, REDUCER_KEY_LEAD_SOURCES } from "../constants";
import ApiClient from "../../api/ApiClient";
import { LeadSourceProps } from "../../types/leadSources";

export interface LeadSourcesState {
  isFetching: boolean;
  leadSources: any[];
  count: number;
}

const initialState: LeadSourcesState = {
  isFetching: false,
  leadSources: [],
  count: 0,
};

const fetchLeadSourceList = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_LEAD_SOURCES}/${ACTION_NAME_FETCH_LEAD_SOURCE_LIST}`,
  async () => await ApiClient.fetchLeadSources(),
);

const addLeadSource = createAsyncThunk<AxiosResponse<any>, { name: string }>(
  `${REDUCER_KEY_LEAD_SOURCES}/addLeadSource`,
  async (leadSource: any) => await ApiClient.addLeadSource(leadSource),
);

const updateLeadSource = createAsyncThunk<AxiosResponse<any>, { leadSource: LeadSourceProps }>(
  `${REDUCER_KEY_LEAD_SOURCES}/updateLeadSource`,
  async (leadSource: any) => await ApiClient.updateLeadSource(leadSource),
);

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_LEAD_SOURCES,
  initialState,
  reducers: {
    resetLeadSources: (state) => {
      state.isFetching = false;
      state.leadSources = [];
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchLeadSourceList.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(fetchLeadSourceList.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.leadSources = payload.data;
      state.count = payload.data.count;
    })
    .addCase(fetchLeadSourceList.rejected, (state) => {
      state.isFetching = false;
    })
    .addCase(addLeadSource.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.leadSources.push(payload.data);
      state.count++;
    })
    .addCase(addLeadSource.rejected, (state) => {
      state.isFetching = false;
    })
    .addCase(addLeadSource.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(updateLeadSource.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      const index = state.leadSources.findIndex((leadSource: any) => leadSource.id === payload.data.id);
      state.leadSources[index] = payload.data;
    })
    .addCase(updateLeadSource.rejected, (state) => {
      state.isFetching = false;
    })
    .addCase(updateLeadSource.pending, (state) => {
      state.isFetching = true;
    })
  },
});

const { resetLeadSources } = actions;

export { fetchLeadSourceList, addLeadSource, resetLeadSources };

export default reducer;