import React from 'react';
import {Typography} from "@mui/material";

import SignUpComponent from "../../components/auth/SignUp";
import { Wrapper } from 'components/auth/styled';

const SignUp = () => {
  return (
    <Wrapper>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Sign Up
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Sign up in your account to continue
      </Typography>
      <SignUpComponent />
    </Wrapper>
  );
}

export default SignUp;
