import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_FEEDBACKS,
  ACTION_NAME_FETCH_FEEDBACKS_LIST,
  ACTION_NAME_FETCH_FEEDBACK_ITEM,
  ACTION_NAME_EDIT_FEEDBACK_ITEM,
} from '../constants'
import ApiClient from 'api/ApiClient';
import { FeedbackProps } from 'types/feedback';

export interface FeedbacksState {
  isFetching: boolean;
  feedbacks: FeedbackProps[];
  count: number;
  page: number;
  selectedFeedbackItem?: FeedbackProps;
}

const initialState: FeedbacksState = {
  isFetching: false,
  feedbacks: [],
  count: 0,
  page: 0,
  selectedFeedbackItem: undefined,
}

const fetchFeedbacksList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  search?: string,
  property?: string,
  type?: "desc" | "asc",
}>(
  `${REDUCER_KEY_FEEDBACKS}/${ACTION_NAME_FETCH_FEEDBACKS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    property,
    type= "asc",
    search,
  }) => {
    const response = await ApiClient.fetchFeedbacksList({ offset, limit, search, property, type });
    return response;
  },
);

const fetchFeedbackItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_FEEDBACKS}/${ACTION_NAME_FETCH_FEEDBACK_ITEM}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchFeedbackItem(id);
    return response;
  },
);

const editFeedbackItem = createAsyncThunk<AxiosResponse<any>, { feedback: FeedbackProps }>(
  `${REDUCER_KEY_FEEDBACKS}/${ACTION_NAME_EDIT_FEEDBACK_ITEM}`,
  async ({
    feedback,
  }) => {
    const response = await ApiClient.editFeedbackItem(feedback);
    return { ...response, feedback };
  },
);

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_FEEDBACKS,
  initialState,
  reducers: {
    resetFeedbacks: (state) => {
      state.isFetching = false;
      state.feedbacks = [];
      state.selectedFeedbackItem = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbacksList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchFeedbacksList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchFeedbacksList.fulfilled, (state, { payload }) => {
        state.feedbacks = payload.data.feedbacks;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(fetchFeedbackItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchFeedbackItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchFeedbackItem.fulfilled, (state, { payload }) => {
        state.selectedFeedbackItem = payload.data;
        state.isFetching = false;
      })
      .addCase(editFeedbackItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editFeedbackItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(editFeedbackItem.fulfilled, (state, { payload }) => {
        // @ts-ignore
        if (state.selectedFeedbackItem.id === payload.feedback.id) {
          // @ts-ignore
          state.selectedFeedbackItem = {
            ...state.selectedFeedbackItem,
            // @ts-ignore
            status: payload.feedback.status
          };
        }
        state.isFetching = false;
      });
  },
});

const {
  resetFeedbacks,
} = actions;

export {
  resetFeedbacks,
  fetchFeedbacksList,
  fetchFeedbackItem,
  editFeedbackItem,
};

export default reducer;
