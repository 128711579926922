import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ACTION_NAME_CREATE_LEAD_MANAGER_ITEM,
  ACTION_NAME_FETCH_LEAD_MANAGER_LIST,
  ACTION_NAME_REMOVE_LEAD_MANAGER_ITEM,
  ACTION_NAME_FETCH_LEAD_MANAGER_ITEM,
  REDUCER_KEY_LEAD_MANAGERS,
  ACTION_NAME_UPDATE_LEAD_MANAGER_ITEM,
} from '../constants';
import ApiClient from '../../api/ApiClient';
import { LeadManagerCreateProps, LeadManagerUpdateProps } from '../../types/leadManagers';
import { User } from "../../types/user";

export interface LeadManagersState {
  isFetching: boolean;
  leadManagers: any[];
  count: number;
  page: number;
  selectedLeadManager: User | undefined;
}

const initialState: LeadManagersState = {
  isFetching: false,
  leadManagers: [],
  count: 0,
  page: 0,
  selectedLeadManager: undefined,
};

const fetchLeadManagerList = createAsyncThunk<
  AxiosResponse<any>,
  { offset?: number; limit?: number; property?: string; type?: 'desc' | 'asc'; search?: string }
>(
  `${REDUCER_KEY_LEAD_MANAGERS}/${ACTION_NAME_FETCH_LEAD_MANAGER_LIST}`,
  async ({ offset = 0, limit = 25, property, type = 'asc', search }) => {
    const response = await ApiClient.fetchLeadManagers();
    return response;
  },
);

const addLeadManagerItem = createAsyncThunk<AxiosResponse<any>, { leadManager: LeadManagerCreateProps }>(
  `${REDUCER_KEY_LEAD_MANAGERS}/${ACTION_NAME_CREATE_LEAD_MANAGER_ITEM}`,
  async ({ leadManager }, { dispatch }) => {
    const response = await ApiClient.createLeadManagerItem(leadManager);
    return response;
  },
);

const removeLeadManagerItem = createAsyncThunk<AxiosResponse<any>, { managerId: number }>(
  `${REDUCER_KEY_LEAD_MANAGERS}/${ACTION_NAME_REMOVE_LEAD_MANAGER_ITEM}`,
  async ({ managerId }, { dispatch }) => {
    const response = await ApiClient.deleteLeadManagerItem(managerId);
    return { ...response, data: { ...response.data, managerId } };
  },
);

const fetchLeadManagerItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_LEAD_MANAGERS}/${ACTION_NAME_FETCH_LEAD_MANAGER_ITEM}`,
  async ({ id }) => {
    return await ApiClient.fetchLeadManagerItem(id);
  },
);

const updateLeadManagerItem = createAsyncThunk<AxiosResponse<any>, { leadManager: LeadManagerUpdateProps }>(
  `${REDUCER_KEY_LEAD_MANAGERS}/${ACTION_NAME_UPDATE_LEAD_MANAGER_ITEM}`,
  async ({ leadManager }) => {
    return await ApiClient.updateLeadManagerItem(leadManager);
  },
);

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_LEAD_MANAGERS,
  initialState,
  reducers: {
    resetLeadManagers: (state) => {
      state.isFetching = false;
      state.leadManagers = [];
    },
    resetSelectedLeadManager: (state) => {
      state.isFetching = false;
      state.selectedLeadManager = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadManagerList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeadManagerList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchLeadManagerList.fulfilled, (state, { payload }) => {
        state.leadManagers = payload.data.leadManagers;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(addLeadManagerItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addLeadManagerItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(addLeadManagerItem.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.leadManagers.push(payload.data);
        state.count++;
      })
      .addCase(fetchLeadManagerItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLeadManagerItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchLeadManagerItem.fulfilled, (state, {payload}) => {
        state.isFetching = false;
        state.selectedLeadManager = payload.data;
      })
      .addCase(removeLeadManagerItem.fulfilled, (state, { payload }) => {
        if (payload.data.success && payload.data.managerId) {
          state.leadManagers = state.leadManagers.filter((manager) => manager.id !== payload.data.managerId);
        }
      })
      .addCase(updateLeadManagerItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateLeadManagerItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateLeadManagerItem.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        if (state.selectedLeadManager) {
          const currentSelectedManagerId = state.selectedLeadManager.id;
          const index = payload.data.findIndex((manager: { id: number; }) => manager.id === currentSelectedManagerId);
          if (index!== -1) {
            state.selectedLeadManager = payload.data[index];
          }
        }
        payload.data.forEach((updatedLeadManager: { id: number; }) => {
          const index = state.leadManagers.findIndex((manager) => manager.id === updatedLeadManager.id);
          if (index!== -1) {
            state.leadManagers[index] = updatedLeadManager;
          }
          else {
            state.leadManagers.push(updatedLeadManager);
            state.count++;
          }  
        });
        
      });
        
  },
});

const { resetLeadManagers, resetSelectedLeadManager } = actions;

export {
  fetchLeadManagerList,
  resetSelectedLeadManager,
  resetLeadManagers,
  addLeadManagerItem,
  removeLeadManagerItem,
  fetchLeadManagerItem,
  updateLeadManagerItem,
};

export default reducer;
